export class Publicos {
  searchLastNewsLetter = '/junaeb/outside/searchLastNewsLetter';
  searchArticle = '/junaeb/outside/searchArticle';
  banner = '/junaeb/outside/banner/public';
  ies = '/junaeb/outside/ies';
  contactMailPublico = '/junaeb/outside/contact/public';
  contactReason = '/junaeb/outside/contactReason/public';
  menuFindAllCommerce  = '/junaeb/outside/menu/findAllCommerce';
  menuFindAll = '/junaeb/outside/menu/findAll';
  recipes = '/junaeb/outside/recipes';
  articles = '/junaeb/outside/findByPlaformIdAndModule';
  validateCaptcha = '/junaeb/outside/validate/captcha';
  searchByRut = '/junaeb/outside/rutSeeker';
}
export class Privados {
  currentCard = '/junaeb/card/curr';
  movements = '/junaeb/card/mov';
  banner = '/junaeb/outside/banner/private';
  checkFirstAccess = '/junaeb/beneficiary/checkFirstAccess';
  getBeneficiaryInfo = '/junaeb/beneficiary/getBeneficiaryInfo';
  changeStatusAndLockCardBeneficiary = '/junaeb/beneficiary/changeStatusAndLockCardBeneficiary';
  saveAuditExpert = '/junaeb/beneficiary/saveAuditExpertRequest';
  disableCard = '/junaeb/card/disable';
  enableCard = '/junaeb/card/enable';
  cardHeader = '/junaeb/card/arching';
  cardHeaderDetail = '/junaeb/card/arch-det';
  training = '/junaeb/generic/movie';
  paymentDynamicCode = '/junaeb/beneficiary/dynamicKey';
  localNearby = '/junaeb/payment-online/localNearby';
  localLastPayments = '/junaeb/payment-online/localLastPayments';
  localMcCode = '/junaeb/payment-online/localMcCode';
  onlinePaymentTrade = '/junaeb/payment-online/online-trade-pay';
  saveAuditFirstAccess = '/junaeb/beneficiary/saveAuditFirstAccess';
  contactReason = '/junaeb/generic/contactReason/private';
  contactMail = '/junaeb/outside/contact/private';
  info = '/junaeb/beneficiary/info';
  paymentRut = '/junaeb/card/paymentRut';
  connectProfile = '/junaeb/connect/profile';
  connectCompanies = '/junaeb/connect/companies?service=junaeb&userType=consumer';
  checkUser = '/junaeb/connect/checkUser';
}
