import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Publicos } from 'src/app/service/endpoints';
import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {Contac} from '../models/common/Contac';
import {retry} from 'rxjs/operators';
import { Articles } from '../dto/Articles';

@Injectable({
  providedIn: 'root'
})

export class PublicService {
  url: string;
  path = '';
  params = new HttpParams();

  constructor(private httpClient: HttpClient,
              private publicos: Publicos)  {
       this.url = environment.baseUrl;
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
  }

  // tslint:disable-next-line:member-ordering
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS, DELETE, PATCH, TRACE, HEAD',
      'Access-Control-Allow-Headers':
        'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
    })
  };

  searchLastNewsLetter(year?: number, month?: number): Observable<any> {
    this.clearPath();
    if (year !== undefined && month !== undefined) {
      this.path = '?year=' + year + '&month=' + month;
    }
    return this.httpClient
      .get<any>(this.url + this.publicos.searchLastNewsLetter + this.path,
        this.httpOptions
      );

  }

  searchArticle(idName: string): Observable<any> {
    this.clearPath();
    this.path = '/' + idName;
    return this.httpClient
      .get<any>(this.url + this.publicos.searchArticle + this.path,
        this.httpOptions
      );
  }


  banner(): Observable<any> {
    this.clearPath();
    return this.httpClient.get<any>(this.url + this.publicos.banner,
      this.httpOptions
    );
  }

  ies(): Observable<any> {
    this.clearPath();
    return this.httpClient.get<any>(this.url + this.publicos.ies + this.path,
      this.httpOptions
    );
  }


  contactMailPublic(contac: Contac): Observable<any> {
    // @ts-ignore
    return this.httpClient
      .post<any>(this.url + this.publicos.contactMailPublico,
        contac, this.httpOptions
      )
      .pipe(
        retry(1),
      );
  }

  searchByRut(email: string, rut: string, sendInfo : number) : Observable<any> {
    this.clearPath();
    this.path = '/' + email +'/'+rut+'/'+ sendInfo;

    return this.httpClient
      .get<any>(this.url + this.publicos.searchByRut + this.path,
        this.httpOptions
      )
  }

  contactReason(): Observable<any> {
    this.clearPath();
    return this.httpClient
      .get<any>(this.url + this.publicos.contactReason,
        this.httpOptions
      );
  }

  menuFindAllCommerce(): Observable<any> {
    this.clearPath();
    return this.httpClient
      .get<any>(this.url + this.publicos.menuFindAllCommerce,
        this.httpOptions
      );
  }


  menuFindAll(): Observable<any> {
    this.clearPath();
    return this.httpClient
      .get<any>(this.url + this.publicos.menuFindAll,
        this.httpOptions
      );
  }



  recipes(): Observable<any> {
    this.clearPath();
    return this.httpClient
      .get<any>(this.url + this.publicos.recipes,
        this.httpOptions
      );
  }

  recipesDetail(id: string): Observable<any> {
    this.clearPath();
    this.path = '/' + id;
    return this.httpClient
      .get<any>(this.url + this.publicos.recipes + this.path,
        this.httpOptions
      );
  }

  articles(limit: number): Observable<any> {
    this.clearPath();
    this.path = '?limit=' + limit;
    return this.httpClient.get<any>(this.url + this.publicos.articles + this.path,
      this.httpOptions
    );
  }


  validateCaptcha(gRecaptchaResponse: string): Observable<any> {
    this.clearPath();
    this.path = '?token=' + gRecaptchaResponse;
    return this.httpClient.get<any>(this.url + this.publicos.validateCaptcha + this.path);
  }


  clearPath() {
    this.path = '';
    this.params = new HttpParams();
  }


  compareArticles(a: Articles, b: Articles) {
    if (a.publishedYear < b.publishedYear) return 1;
    if (a.publishedYear > b.publishedYear) return -1;

    if (a.publishedMonth < b.publishedMonth) return 1;
    if (a.publishedMonth > b.publishedMonth) return -1;
    
    return 0;
  }


}
