export class UserProfile {
  infoUser: UserProfileInfo;
  permissions: UserProfilePermissions;
  restrictions: UserProfileRestrictions;
}

export class UserProfileInfo {
  id: number; // '1234';
  name: string; // 'Luis Hernan';
  lastName: string; // 'Molina Fuentes';
  userRut: string; // '12345678-K';
  mail: string; // 'lmolina@miempresa.com';
  mobile: string; // '+56998765432';
  clientRut: string; // '98765432-2';
  clientDesc: string; // 'Sodexo';
  costCenterId: string; // 'Sodexo';
  costCenterDesc: string; // 'Sodexo';
  divisionId: string; // 'Sodexo';
  divisionDesc: string; // 'Sodexo';
  active: boolean; // 'Sodexo';
  nameIes: string;
  typeIes: string;
  iesId: number;
  career: string;
  careerId: number;
  yearIncome: number;
  paymentRutActivated: boolean;
  expirationDate: string;

}

export class UserProfilePermissions {
  isAdministrator: boolean;
  isBookingForMe: boolean;
  isBookingForOtherCorporative: boolean;
  isBookingForGuest: boolean;
}

export class UserProfileRestrictions {
  isMandatoryRUT: boolean;
  isMandatoryDestination: boolean;
  hasHourRestriction: UserProfileHourRestrictions;
  isWeekendAndHolidays: boolean;
  isBusinessDay: boolean;
  hasLimitUsage: UserProfileLimitUsages;
  hasAlertUsage: UserProfileAlertUsages;
}

export class UserProfileAlertUsages {
  isActive: boolean;
  amount: string;
}

export class UserProfileLimitUsages {
  isActive: boolean;
  amount: string;
}

export class UserProfileHourRestrictions {
  isActive: boolean;
  start: string;
  end: string;
}