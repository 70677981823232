import {AbstractControl, FormGroup } from "@angular/forms";

declare var showModalError: any;

export function validateNumber(control:AbstractControl) {
    if (control && (control.value !==null || control.value !== undefined)  && !isNaN(control.value) ){
        return null;
    }
    return {
            invalidNumber: true
    }
}

export function validateEmail(control:AbstractControl) {

    let expr = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (    control && 
            (
                ( (control.value !==null || control.value !== undefined) && expr.test(control.value))
            ||  (control.value ===null || control.value === undefined || control.value=="")
            )
       ) {
        return null;
    }   
    return {
            invalidEmail: true
    }
}


export function toHex(str) {
    var hex = '';
    for(var i=0;i<str.length;i++) {
        hex += ''+str.charCodeAt(i).toString(16);
    }
    return hex;
}

export function toAscii(str) {
	var hex  = '';
	for (var n = 0; n < str.length; n += 2) {
		hex += String.fromCharCode(parseInt(str.substr(n, 2), 16));
	}
	return hex;
 }

export function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }
 
