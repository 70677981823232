import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { PublicService } from '../../../service/public.service';
import { PrivateHeaderComponent } from '../private-header/private-header.component';
import { Recipes } from '../../../dto/Recipes';
import {ActivatedRoute, Router} from '@angular/router';
import { CurrentCard } from '../../../dto/CurrentCard';
import {PrivateService} from '../../../service/private.service';
import { AuthGuard } from '../../../guards/auth/auth.guard';



@Component({
  selector: 'app-private-recipes-detail',
  templateUrl: './private-recipes-detail.component.html',
  styleUrls: ['./private-recipes-detail.component.css']
})
export class PrivateRecipesDetailComponent implements OnInit {

  @ViewChild(PrivateHeaderComponent, {static: true}) privateHeaderComponent: PrivateHeaderComponent;
  currentUser: any;
  currentCard: CurrentCard;
  private sub: any;
  recipe: Recipes;

  titleError: string;
  messageError: string;

  constructor(private publicService: PublicService,
              private cd: ChangeDetectorRef,
              private privateService: PrivateService,
              private authService: AuthGuard,
              private readonly router: Router,
              private route: ActivatedRoute) {
    this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));
    this.currentCard = JSON.parse(localStorage.getItem('currentCard'));
  }

  ngOnInit() {
    this.privateHeaderComponent.menuActive = 'm_vida-sana';
    this.sub = this.route.params.subscribe(params => {
      const id = params['idName'];
      this.getRecipes(id);
    }, error => {
      // @ts-ignore
      this.errorMessage(error);
    });
  }

  getRecipes(id: string) {
    this.publicService.recipesDetail(id).subscribe((data: any) => {
      this.recipe = data;
      // tslint:disable-next-line:triple-equals
      if (this.recipe.category.trim() == 'Desayuno') {
        this.recipe.categoryRecipe = 'starter';
        // tslint:disable-next-line:triple-equals
      } else if (this.recipe.category.trim() == 'Plato de fondo') {
             this. recipe.categoryRecipe = 'main-course';
        // tslint:disable-next-line:triple-equals
      } else if  (this.recipe.category.trim() == 'Ensaladas') {
        this.recipe.categoryRecipe = 'dessert';
      } else {
        this.recipe.categoryRecipe = 'main-course';
      }
    }, error => {
      // @ts-ignore
      this.errorMessage(error);
    });
  }


  errorMessage(error) {
    if (error.titleError && error.messageError) {
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }

  logout() {
    this.authService.logout(false);
  }
}
