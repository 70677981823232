import {Component, OnInit, ViewChild} from '@angular/core';
import {PrivateService} from '../../../service/private.service';
import { AuthGuard } from '../../../guards/auth/auth.guard';
import {Router} from '@angular/router';
import {PrivateHeaderComponent} from '../private-header/private-header.component';
import {CurrentCard} from '../../../dto/CurrentCard';

@Component({
  selector: 'app-private-good-practices',
  templateUrl: './private-good-practices.component.html',
  styleUrls: ['./private-good-practices.component.css']
})
export class PrivateGoodPracticesComponent implements OnInit {

  @ViewChild(PrivateHeaderComponent, {static: true}) privateHeaderComponent: PrivateHeaderComponent;

  currentUser: any;
  currentCard: CurrentCard;

  constructor( private privateService: PrivateService,
               private authService: AuthGuard,
               private readonly router: Router) {
    this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));
    this.currentCard = JSON.parse(localStorage.getItem('currentCard'));

  }

  ngOnInit() {
  }

  logout() {
    this.authService.logout(false);
  }

}
