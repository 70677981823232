export class UserData {
  // tslint:disable-next-line:indent
	'beneficiaryFiscalId': string;
  // tslint:disable-next-line:indent
	'name': string;
  // tslint:disable-next-line:indent
	'secondName': string;
  // tslint:disable-next-line:indent
	'lastName': string;
  // tslint:disable-next-line:indent
	'secondLastName': string;
  // tslint:disable-next-line:indent
	'email': string;
  // tslint:disable-next-line:indent
	'phone': string;
  // tslint:disable-next-line:indent
	'ies': string;
  // tslint:disable-next-line:indent
	'careerId': number;
  // tslint:disable-next-line:indent
	'career': string;
  // tslint:disable-next-line:indent
	'typeIes': string;
  // tslint:disable-next-line:indent
	'iesId': string;
  // tslint:disable-next-line:indent
	'yearIncome': number;

}
