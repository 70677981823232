import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { HeaderComponent } from '../../shared/footer/header/header.component';
import { PublicService } from '../../service/public.service';
import {Router} from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-buscador-rut',
  templateUrl: './buscador-rut.component.html',
  styleUrls: ['./buscador-rut.component.css']
})
export class BuscadorRutComponent implements OnInit {

  @ViewChild(HeaderComponent, {static: true}) headerComponent: HeaderComponent;

  submitted = true;
  success = false;
  rutSearch: FormGroup;
  notificacion = false;
  serverResponse = '';
  titleError: string;
  messageError: string;
  attempts = 0;



  constructor(private formBuilder: FormBuilder,
              private readonly router: Router,
              private publicService: PublicService,
              private reCaptchaV3Service: ReCaptchaV3Service) {
      this.rutSearch = this.formBuilder.group({
        rut: new FormControl('', Validators.compose([Validators.required])),
        mail: new FormControl('', Validators.compose([Validators.required])),
        acepto: new FormControl('', Validators.compose([Validators.required])),
        notifica: false,


      });

  }

  ngOnInit() {
    this.headerComponent.menuActive = 'm_buscador_rut';

  }

  onSubmit() {

    this.attempts = this.attempts+1;
    let dv = this.rutSearch.value.rut.slice(-1);
    let  rut = this.rutSearch.value.rut.slice(0, -1);
    let validated = this.verificaDv(parseInt(rut));

    if (validated != dv ){
      this.rutSearch.controls['rut'].setErrors({'invalid': true});
    }

    if (this.rutSearch.value.acepto == false || this.rutSearch.value.acepto != true){
      this.rutSearch.controls['acepto'].setErrors({'invalid': true});
    }

    if (this.rutSearch.invalid || validated != dv) {
      this.submitted = false;
      return;
    }
    this.success = true;

    if (this.attempts <= 3){
      this.searchbeneficiary(rut+dv);
    }else {
      this.reCaptchaV3Service.execute(environment.captcha.siteKey, 'SearchByRut', (token) => {
        this.searchbeneficiary(rut+dv);
      });

    }

  }

  searchbeneficiary(rut){
    let sendInfo = (this.rutSearch.value.notifica == true)?1:0;
    this.publicService.searchByRut(this.rutSearch.value.mail,
      rut, sendInfo).subscribe((data: any) => {
      this.serverResponse = data.response;

      if (data.status== true){
        this.router.navigate(['/buscador-rut-beneficiario']);
      } else {
        this.router.navigate(['/buscador-rut-no-beneficiario']);
      }
    }, error => {
      this.errorMessage(error);
    });
  }

  errorMessage(error) {
    if (error.titleError && error.messageError) {
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }

  verificaDv(T){
		var M=0,S=1;
		for(;T;T=Math.floor(T/10))
			S=(S+T%10*(9-M++%6))%11;
    return S?S-1:'K';
  }

}
