import { Component, OnInit, ViewChild } from '@angular/core';
import { PrivateHeaderComponent } from '../private-header/private-header.component';
import { CurrentCard } from '../../../dto/CurrentCard';
import {PrivateService} from '../../../service/private.service';
import {Router} from '@angular/router';
import { AuthGuard } from '../../../guards/auth/auth.guard';



@Component({
  selector: 'app-private-faq',
  templateUrl: './private-faq.component.html',
  styleUrls: ['./private-faq.component.css']
})
export class PrivateFaqComponent implements OnInit {

  @ViewChild(PrivateHeaderComponent, {static: true}) privateHeaderComponent: PrivateHeaderComponent;
  currentUser: any;
  currentCard: CurrentCard;
  constructor( private privateService: PrivateService,
               private authService: AuthGuard,
               private readonly router: Router) {
    this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));
    this.currentCard = JSON.parse(localStorage.getItem('currentCard'));

   }

  ngOnInit() {
  }

  logout() {
    this.authService.logout(false);
  }
}
