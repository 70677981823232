import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { PrivateHeaderComponent } from 'src/app/components/private-components/private-header/private-header.component';
import { Training } from 'src/app/dto/Training';
import { PrivateService } from 'src/app/service/private.service';
import { ChangeDetectorRef } from '@angular/core';
import { CurrentCard } from '../../../dto/CurrentCard';
import {Router} from '@angular/router';
import { AuthGuard } from '../../../guards/auth/auth.guard';


declare var showModalTraining: any;

@Component({
  selector: 'app-private-training',
  templateUrl: './private-training.component.html',
  styleUrls: ['./private-training.component.css']
})


export class PrivateTrainingComponent implements OnInit {

  @ViewChild(PrivateHeaderComponent, {static: true}) privateHeaderComponent: PrivateHeaderComponent;
  currentUser: any;
  currentCard: CurrentCard;
  trainings1: Array<Training> = [];
  trainings2: Array<Training> = [];
  auxIdName: string;


  titleError: string;
  messageError: string;


  constructor(
    private privateService: PrivateService,
    private readonly router: Router,
    private authService: AuthGuard,
              private cd: ChangeDetectorRef) {
    this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));
    this.currentCard = JSON.parse(localStorage.getItem('currentCard'));
  }

  ngOnInit() {
    this.privateHeaderComponent.menuActive = 'm_vida-sana';
    this.getTraining();
  }

  getTraining() {
   this.privateService.movie(4).subscribe((data: any) => {
      data.forEach(valor => {
        if (this.auxIdName == null) {
            this.trainings1.push(valor);
            this.auxIdName = valor.idName;
        } else {
          this.trainings2.push(valor);
        }
      });
    }, error => {
      this.errorMessage(error);
    });
  }



  // tslint:disable-next-line:member-ordering
   moviePrint = false;
   functionStartMovie() {
     this.moviePrint = true;
     this.cd.detectChanges();
   }

   functionShowModalTraining(id) {
     // tslint:disable-next-line:no-unused-expression
     new showModalTraining(id);
   }

  errorMessage(error) {
    if (error.titleError && error.messageError) {
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }
  logout() {
    this.authService.logout(false);
  }
}
