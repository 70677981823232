import { Component, OnInit } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import {PrivateService} from '../../../service/private.service';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import { AuthGuard } from '../../../guards/auth/auth.guard';
import { CookieService } from 'ngx-cookie-service';

declare var showModalInactivity: any;
declare var closeLightBox: any;

@Component({
  selector: 'app-private-header',
  templateUrl: './private-header.component.html',
  styleUrls: ['./private-header.component.css']
})
export class PrivateHeaderComponent implements OnInit {
  menuActive = '';
  name = '';
  lastName = '';
  currentUser: any;
  urlBuscador;
  userIdleService: any;
  url = '';
  redirectToCases = environment.urlMyCases;
  public data:any;

  constructor(private userIdle: UserIdleService,
              private privateService: PrivateService,
              private authService: AuthGuard,
              private readonly router: Router,
              private cookieService: CookieService) {
      this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));

    this.urlBuscador = environment.tradeSearchEngine;
  }

  ngOnInit() {
   
    this.name = this.currentUser.infoUser.name;
    this.lastName = this.currentUser.infoUser.lastName;
    this.userIdle.startWatching();
    this.userIdleService = this.userIdle.onTimerStart().subscribe((count: number) => {
        this.inactivityMessage(count);
    });
  }

  logout() {
    this.userIdle.stopWatching();
    this.cerrarLightBox('video-0');
    this.cerrarLightBox('video-1');
    this.cerrarLightBox('video-2');
    this.cerrarLightBox('myModal');
    this.cerrarLightBox('myModalInactivity');
    this.authService.logout(false);
  }

  inactivityMessage(count) {
    const time = 10;
    if (count > time) {
      this.logout();
    }
    // tslint:disable-next-line:triple-equals
    if (count == 1) {
      // tslint:disable-next-line:no-unused-expression
      new showModalInactivity();
    }
  }

  restartInactivity(modal) {
    this.userIdle.resetTimer();
    this.cerrarLightBox(modal);
  }

  cerrarLightBox(modal) {
    // tslint:disable-next-line:no-unused-expression
    new closeLightBox(modal);
  }

  redirectMyCases(){
    const pan =JSON.parse( localStorage.getItem('currentCard'))
    const dataUserProfile = JSON.parse(localStorage.getItem('currentLoggedUser'));
    this.data = {
      token:localStorage.getItem('access_token'),
      name:dataUserProfile.infoUser.name,
      lastName:dataUserProfile.infoUser.lastName,
      email:dataUserProfile.infoUser.mail,
      mobile:dataUserProfile.infoUser.mobile,
      ies:dataUserProfile.infoUser.nameIes,
      sourceUrl: window.location.href,
      origin: 'web',
      companyId: '609080000',
      service:'Junaeb',
      profile:'consumer',
      rutConsumer:pan.pan
    } 
    if(this.cookieService.get('cookieCases')){
      this.cookieService.delete('cookieCases')
    }
    this.cookieService.set('cookieCases',JSON.stringify(this.data),365, '/') 
    this.privateService.redirectToCases(this.redirectToCases)
  }
}
