import { Component, OnInit } from '@angular/core';
import { PrivateService } from '../../service/private.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-centro-de-ayuda',
  templateUrl: './centro-de-ayuda.component.html',
  styleUrls: ['./centro-de-ayuda.component.css']
})
export class CentroDeAyudaComponent implements OnInit {
public data:any;
redirectToCases = environment.urlCreateCase
  constructor(private privateService:PrivateService,private cookieService: CookieService) { }

  ngOnInit() {
  
  }

  sendDataredirectToCases(){
    const pan =JSON.parse( localStorage.getItem('currentCard'))
    const dataUserProfile = JSON.parse(localStorage.getItem('currentLoggedUser'));
    this.data = {
      token:localStorage.getItem('access_token'),
      name:dataUserProfile.infoUser.name,
      lastName:dataUserProfile.infoUser.lastName,
      email:dataUserProfile.infoUser.mail,
      mobile:dataUserProfile.infoUser.mobile,
      ies:dataUserProfile.infoUser.nameIes,
      sourceUrl: window.location.href,
      origin: 'web',
      companyId: '609080000',
      service:'Junaeb',
      profile:'consumer',
      rutConsumer:pan.pan
    } 

    if(this.cookieService.get('cookieCases')){
      this.cookieService.delete('cookieCases')
    }
    this.cookieService.set('cookieCases',JSON.stringify(this.data),365, '/') 
    this.privateService.redirectToCases(this.redirectToCases)
  } 
}
