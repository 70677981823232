import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderComponent } from '../../shared/footer/header/header.component';




@Component({
  selector: 'app-good-practices',
  templateUrl: './good-practices.component.html',
  styleUrls: ['./good-practices.component.css']
})
export class GoodPracticesComponent implements OnInit {
  @ViewChild(HeaderComponent, {static: true}) headerComponent: HeaderComponent;
  constructor() { }

  ngOnInit() {
    this.headerComponent.menuActive = 'm_sobre-la-beca';

  }

}
