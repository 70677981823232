import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root',
})
export class ScriptChatbotLoaderService {
  constructor(private http: HttpClient) {}

  loadChatbotScript(): void {
    const config = environment.chatbot;

    const scriptElement = document.getElementById('genesys-script') as HTMLScriptElement;
    if (scriptElement) {
      scriptElement.type = 'text/javascript';
      scriptElement.charset = 'utf-8';
      scriptElement.innerHTML = `
        (function (g, e, n, es, ys) {
          g['_genesysJs'] = e;
          g[e] = g[e] || function () {
            (g[e].q = g[e].q || []).push(arguments);
          };
          g[e].t = 1 * new Date();
          g[e].c = es;
          ys = document.createElement('script');
          ys.async = 1;
          ys.src = n;
          ys.charset = 'utf-8';
          document.head.appendChild(ys);
        })(window, 'Genesys', '${config.url}', {
          environment: '${config.environment}',
          deploymentId: '${config.deploymentId}'
        });
      `;
    } else {
      console.error('Placeholder for Genesys script not found in index.html');
    }
  }
}
