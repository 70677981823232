import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModule } from '@angular/core';
import { FooterComponent } from '../../shared/footer/footer.component';
import { HelpCenterComponent } from '../help-center/help-center.component';
import {AppModule} from '../../app.module';
import { HeaderComponent } from '../../shared/footer/header/header.component';



@Component({
  selector: 'app-sobre-la-beca',
  templateUrl: './sobre-la-beca.component.html',
  styleUrls: ['./sobre-la-beca.component.css']
})


@NgModule({
  imports: [
    AppModule
  ],
  declarations: [
    HelpCenterComponent,
    FooterComponent
  ]
})

export class SobreLaBecaComponent implements OnInit {

  @ViewChild(HeaderComponent, {static: true}) headerComponent: HeaderComponent;
  constructor() {
  }
  ngOnInit() {
    this.headerComponent.menuActive = 'm_sobre-la-beca';

  }

}
