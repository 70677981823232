import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderComponent } from '../../shared/footer/header/header.component';



@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  @ViewChild(HeaderComponent, {static: true}) headerComponent: HeaderComponent;

  constructor() { }

  ngOnInit() {
    // tslint:disable-next-line:no-unused-expression
    this.headerComponent.menuActive = 'm_sobre-la-beca';
  }

}
