import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { PrivateService } from '../../../service/private.service';

defineLocale('es', esLocale);

@Component({
  selector: 'app-private-card-request',
  templateUrl: './private-card-request.component.html',
  styleUrls: ['./private-card-request.component.css']
})
export class PrivateCardRequestComponent implements OnInit {

  confirmedCardRequest = false;
  loadingRequest = false;
  @Output() ConfirmLockClic = new EventEmitter<any>();


  constructor(private cd: ChangeDetectorRef, private privateService: PrivateService) {
  }

  ngOnInit() {
  }

  confirmCardRequest() {
    this.loadingRequest = true;
    this.privateService.changeStatusAndLockCardBeneficiary(null).subscribe((data: any) => {
      //console.log(data);
      if (data.changeCardRequestFlagResponse && data.lockCardResponse) {
        this.confirmedCardRequest = true;
        this.ConfirmLockClic.emit(true);
        this.cd.detectChanges();
        this.loadingRequest = false;
      }

    }, error => {
      this.errorMessage(error);
    });

    
  }

  errorMessage(error) {
    if (error.titleError && error.messageError) {
      //mensaje de error
    }
  }

}
