import { Component, OnInit, ViewChild } from '@angular/core';
import { PublicService } from '../../../service/public.service';
import { Articles } from '../../../dto/Articles';
import { PrivateHeaderComponent } from '../private-header/private-header.component';
import { CurrentCard } from '../../../dto/CurrentCard';
import {PrivateService} from '../../../service/private.service';
import {Router} from '@angular/router';
import { AuthGuard } from '../../../guards/auth/auth.guard';




@Component({
  selector: 'app-private-articles',
  templateUrl: './private-articles.component.html',
  styleUrls: ['./private-articles.component.css']
})
export class PrivateArticlesComponent implements OnInit {
  @ViewChild(PrivateHeaderComponent, {static: true}) privateHeaderComponent: PrivateHeaderComponent;
  articles: Array<Articles> = [];
  localArticles: Articles[];
  currentUser: any;
  currentCard: CurrentCard;
  titleError: string;
  messageError: string;

  constructor(private publicService: PublicService,
              private privateService: PrivateService,
              private authService: AuthGuard,
              private readonly router: Router) {
    this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));
    this.currentCard = JSON.parse(localStorage.getItem('currentCard'))
  }

  ngOnInit() {
    // tslint:disable-next-line:no-unused-expression
    if (localStorage.getItem('currentCard') != null) {
    }
    this.privateHeaderComponent.menuActive = 'm_vida-sana';
    this.getArticles();
  }

  getArticles() {
    this.publicService.articles(20).subscribe((data: any) => {
      this.localArticles = data;
      this.localArticles.sort(this.publicService.compareArticles);
      this.localArticles.forEach(article => {
        article.landing = article.landing + '<span class=\'plus\'>+</span>';
        this.articles.push(article);
      });
    }, error => {
      this.errorMessage(error);
    });
  }


  errorMessage(error) {
    if (error.titleError && error.messageError) {
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }

  logout() {
    this.authService.logout(false);
  }
}
