import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CurrentCard } from 'src/app/dto/CurrentCard';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import {validateNumber, formatNumber, toHex, toAscii} from 'src/app/security/helpers/custom.validator';
import { PrivateService } from '../../../service/private.service';
import { LocalNearby } from '../../../dto/LocalNearby';
import { CardHeader } from 'src/app/dto/CardHeader';
import { ReCaptchaV3Service } from 'ngx-captcha';
import {Router} from '@angular/router';
import { AuthGuard } from '../../../guards/auth/auth.guard';


declare var loadingButton: any;
declare var loadingButtonReset: any;
declare var loadingButtonSearch: any;

@Component({
  selector: 'app-private-online-payment',
  templateUrl: './private-online-payment.component.html',
  styleUrls: ['./private-online-payment.component.css']
})

export class PrivateOnlinePaymentComponent implements OnInit {
  onlinePaymentForm: any;
  mcCodeForm: any;
  confirmForm: any;
  currentCard: CurrentCard;
  currentUser: any;
  paymentStatus: any;

  submittedConfirm = true;
  submitted = true;
  step1  = true;
  step2 = false;
  success = false;
  fail = false;
  loading = false;
  servicio1list = false;
  servicio2list = false;
  servicio3list = true;

  // tslint:disable-next-line:comment-format
  //step1
  latitud: any;
  longitud: any;
  localNearby: Array<LocalNearby> = [];
  localLastPayments: Array<LocalNearby> = [];
  localMcCode: Array<LocalNearby> = [];
  pan = '';
  // @ts-ignore
  public payment: Paymen = {};

  // tslint:disable-next-line:comment-format
  //step2
  mcCode: string;
  localName: string;
  monto: number;


  nameComplete: string;
  error: string;
  cardHeader: CardHeader;



  constructor( private formBuilder: FormBuilder,
               private cd: ChangeDetectorRef,
               private privateService: PrivateService,
               private authService: AuthGuard,
               private readonly router: Router,
               private reCaptchaV3Service: ReCaptchaV3Service) {

    this.onlinePaymentForm = this.formBuilder.group({
      monto: new FormControl('', Validators.compose([Validators.required, validateNumber]))
      }, {validator: this.validateOnlinePaymentForm}
    );


    this.mcCodeForm = this.formBuilder.group({
      codigo: new FormControl('', Validators.compose([Validators.required, validateNumber]))
    });
    this.confirmForm = this.formBuilder.group({
      // tslint:disable-next-line:max-line-length
      passwordUser: ['', Validators.compose([Validators.required, Validators.maxLength(4), Validators.minLength(4), Validators.pattern('[0-9]*')])]
    });
  }

  validateOnlinePaymentForm(group: FormGroup) {
    const monto = group.controls.monto.value;
    if (monto < 200) {
      group.controls.monto.setErrors({'notValidAmount': true});
      return false;
    }
  }


  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));
    this.cardHeader = JSON.parse(localStorage.getItem('cardHeader'));
    this.nameComplete = (this.currentUser.infoUser.name != null ) ? this.currentUser.infoUser.name : '';
    this.nameComplete += (this.currentUser.infoUser.lastName != null ) ? this.currentUser.infoUser.lastName : '';
    this.currentCard = JSON.parse(localStorage.getItem('currentCard'));
    this.pan = toAscii(this.currentCard.pan);
    const finPan = this.pan.substr(10);
    this.pan = '**********' + finPan;
    this.servicio1();
    this.servicio2();

  }

  servicio1() {
    if (navigator.geolocation) {
      this.loading = true;
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitud = position.coords.latitude;
        this.longitud = position.coords.longitude;
        this.cd.detectChanges();
        this.privateService.localNearby(this.latitud, this.longitud).subscribe((data) => {

          if(data.messageError ==null){

            data.forEach(local => {
              if (('idResponse' in local) && ('titleError' in local) && ('messageError' in local)) {
                this.servicio1list = false;
                this.error = local.messageError;
                this.cd.detectChanges();
                return this.error;
              } else {
                if (local.distance !== undefined) {
                  local.distance = (local.distance / 1000).toFixed(2);
                }
                this.localNearby.push(local);
                this.servicio1list = true;
                this.cd.detectChanges();
              }
            });

          }else{
            this.servicio1list = false;
            this.error = data.messageError;
            this.cd.detectChanges();
            return this.error;
          }

          this.loading = false;
          this.cd.detectChanges();
        }, error => {
          this.servicio1list = false;
          this.error = error.messageError;
          this.cd.detectChanges();
          return this.error;
        });
      });
    } else {
      this.error = 'Tu navegador no soporta la geolocalización, actualiza tu navegador.';
      this.cd.detectChanges();
      return this.error;
    }
    this.cd.detectChanges();
  }

  servicio2() {
    this.loading = true;
    this.privateService.localLastPayments().subscribe((data) => {

     if (data.messageError == null) {
       data.forEach(local => {
         if (local.distance !== undefined) {
           local.distance = (local.distance / 1000).toFixed(2);
         }
         this.localLastPayments.push(local);
         this.servicio2list = true;
       }, error => {
         this.servicio2list = false;
         this.error = error.messageError;
         return this.error;
       });
     }

      this.loading = false;
    });
    this.cd.detectChanges();
  }


  servicio3() {
    this.localMcCode = new Array<LocalNearby>();
    if (this.mcCodeForm.invalid) {
      this.submitted = false;
      return;
    }
    // tslint:disable-next-line:no-unused-expression
    new loadingButton('continuar-mcCode');
    this.privateService.localMcCode(this.mcCodeForm.controls.codigo.value).subscribe((data) => {
      if (data.messageError == null) {
        // tslint:disable-next-line:no-unused-expression
        new loadingButtonReset('continuar-mcCode');
        data.forEach(local => {
          if (local.distance !== undefined) {
            local.distance = (local.distance / 1000).toFixed(2);
          }
          this.localMcCode.push(local);
          this.servicio3list = true;
        }, error => {
          this.error = error.messageError;
          this.servicio3list = false;
        });

      }else{
        this.error = data.messageError;
        this.servicio3list = false;
        this.loading = false;
        // tslint:disable-next-line:no-unused-expression
        new loadingButtonReset('continuar-mcCode');
      }
      this.loading = false;
    }, error => {
      this.error = error.messageError;
      this.servicio3list = false;
      this.loading = false;
      // tslint:disable-next-line:no-unused-expression
      new loadingButtonReset('continuar-mcCode');
    });
    this.cd.detectChanges();
  }


  loadingButtonSearch() {
    // tslint:disable-next-line:no-unused-expression
    new loadingButtonSearch();
  }

  submitStep1(mcCode, localName, index) {
    if (this.onlinePaymentForm.invalid) {
      this.submitted = false;
      return;
    }
    // tslint:disable-next-line:no-unused-expression
    new loadingButton('continuar-' + index);
    this.mcCode = mcCode;
    this.localName = localName;
    this.monto = formatNumber(this.onlinePaymentForm.controls.monto.value);
    this.step1 = false;
    this.step2 = true;
    this.cd.detectChanges();
  }

  submitStep2() {
    if (this.confirmForm.invalid) {
      this.submittedConfirm = false;
      return;
    }
    // tslint:disable-next-line:no-unused-expression
    new loadingButton('pagar');
    // tslint:disable-next-line:radix
    this.payment.amount =  parseInt(this.onlinePaymentForm.controls.monto.value);
    this.payment.key = toHex(this.confirmForm.controls.passwordUser.value);
    this.payment.name =  this.nameComplete;
    // tslint:disable-next-line:radix
    this.payment.subsidiaryId = parseInt(this.mcCode);
    this.payment.pan = this.currentCard.pan;
    this.privateService.onlinePaymentTrade(
      this.payment).subscribe((data: any) => {
          if ((data.message !== 'Pago exitoso'
              && data.httpStatusPaymentOnline !== '200')) {
              this.error = data.message;
              this.step1 = false;
              this.step2 = false;
              this.success = false;
              this.fail = true;

                this.privateService.cardHeader(
                  // tslint:disable-next-line:no-shadowed-variable
                this.currentCard.pan).subscribe((data: any) => {
                this.cardHeader = data;
                localStorage.setItem('cardHeader', JSON.stringify(this.cardHeader));
              });
          } else {
            this.paymentStatus = data;
            this.step1 = false;
            this.step2 = false;
            this.success = true;
            this.fail = false;
          }
      }, error => {
        this.error = error.messageError;
        this.step1 = false;
        this.step2 = false;
        this.success = false;
        this.fail = true;
      });

    this.cd.detectChanges();
  }


  logout() {
    this.authService.logout(false);
  }

}
