import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {retry, tap} from 'rxjs/operators';
import {UserCIAMResponse, data} from '../models/common/user';
import {UserProfile} from '../models/common/user-profile';
import {Payment} from '../models/common/Payment';
import {environment} from '../../environments/environment';
import {Privados} from './endpoints';



@Injectable({
  providedIn: 'root'
})

export class PrivateService {
  url = '';
  path = '';
  params = new HttpParams();
  public userLogged: UserProfile;
 access_token = localStorage.getItem('access_token')
  constructor(private httpClient: HttpClient,
              private privados: Privados) {
    this.url = environment.baseUrl;
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, OPTIONS, DELETE, PATCH, TRACE, HEAD',
      'Access-Control-Allow-Headers':
        'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
    })
  };

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
  }



  /* Recuperar del storage la variable de sesion */
  getUserLoggedIn(): UserProfile {
    return JSON.parse(localStorage.getItem('currentLoggedUser'));
  }
  clearPath() {
    this.path = '';
    this.params = new HttpParams();
  }

  destroyUserLoggedIn() {

    localStorage.clear();
    sessionStorage.clear();
  }


  getUserProfileConnect(token: string): Observable<any> {
    return this.httpClient
      .get<UserCIAMResponse>(
        this.url + this.privados.connectProfile,
        this.httpOptions
      )
      .pipe(
        retry(1),
      );
  }

  getUserCompaniesConnect(token: String): Observable<any> {
    return this.httpClient
      .get<UserCIAMResponse>(
        this.url + this.privados.connectCompanies,
        this.httpOptions
      )
      .pipe(
        retry(1),
      );
  }

  getRefreshJwt(token: string, ciamRefreshJwt: string): Observable<any> {
    return this.httpClient
      .post<any>(
        ciamRefreshJwt,
        {token},
        this.httpOptions
      )
      .pipe(
        retry(1),
      );
  }


  /* Asignarle variable de sesión */
  setUserLoggedIn(user: UserProfile) {
    this.userLogged = user;
    localStorage.setItem('currentLoggedUser', JSON.stringify(user));
  }


  checkFirstAccess() {
    return this.httpClient
      .get<any>(
        this.url + this.privados.checkFirstAccess,
        this.httpOptions
      )
      .pipe(
        retry(1),
      );
  }

  getBeneficiaryInfo() {
    return this.httpClient
      .get<any>(
        this.url + this.privados.getBeneficiaryInfo,
        this.httpOptions
      )
      .pipe(
        retry(1),
      );
  }

  changeStatusAndLockCardBeneficiary(reason: string): Observable<any> {
    this.clearPath();
    // @ts-ignore
    if (reason != null){
    this.params = new HttpParams()
      .set('reason', reason);
    }
    return this.httpClient
      .put<any>(this.url + this.privados.changeStatusAndLockCardBeneficiary,
        // @ts-ignore
        null, {params: this.params}, this.httpOptions
      ).pipe(
        retry(1),
      );
  }

  currentCard(): Observable<any> {

    return this.httpClient
      .post<any>(this.url + this.privados.currentCard,
        this.httpOptions
      )
      .pipe(
        retry(1),
      );
  }


  cardHeaderDetail(): Observable<any> {

    return this.httpClient
      .get<any>(this.url + this.privados.cardHeaderDetail,
        this.httpOptions
      )
      .pipe(
        retry(1),
      );
  }


  cardHeader(pan: string): Observable<any> {
    this.clearPath();
    this.params = new HttpParams().set('pan', pan);
    // @ts-ignore
    return this.httpClient
      .get<any>(this.url + this.privados.cardHeader,
        // @ts-ignore
        {params: this.params}, this.httpOptions
      )
      .pipe(
        retry(1),
      );
  }

  banner(): Observable<any> {
    return this.httpClient.get<any>(this.url + this.privados.banner,
      this.httpOptions
    ).pipe(
      retry(1),
    );
  }

  contactReason(): Observable<any> {
    return this.httpClient.get<any>(this.url + this.privados.contactReason,
      this.httpOptions
    ).pipe(
      retry(1),
    );
  }


  contactMail(subject: string, message: string, template: string): Observable<any> {
    this.clearPath();
    this.params = new HttpParams()
      .set('subject', subject)
      .set('message', message)
      .set('template', template);
    // @ts-ignore
    return this.httpClient
      .post<any>(this.url + this.privados.contactMail, null,
        // @ts-ignore
        {params: this.params}, this.httpOptions
      ).pipe(
        retry(1),
      );
  }


  saveAuditExpert(name: string, email: string, ies: string, iesId: string, reason: string): Observable<any> {
    this.clearPath();
    this.params = new HttpParams()
      .set('name', name)
      .set('email', email)
      .set('ies', ies)
      .set('iesId', iesId)
      .set('reason', reason);

    // @ts-ignore
    return this.httpClient
      .post<any>(this.url + this.privados.saveAuditExpert,
        // @ts-ignore
        null, {params: this.params}, this.httpOptions
      ).pipe(
        retry(1),
      );
  }

  info(): Observable<any> {
    return this.httpClient
      .get<any>(this.url + this.privados.info,
        this.httpOptions
      ).pipe(
        retry(1),
      );
  }


  disableCard(pan: string, motive: string): Observable<any> {
    this.clearPath();
    this.params = new HttpParams()
      .set('pan', pan)
      .set('motive', motive);
    // @ts-ignore
    return this.httpClient
      .put<any>(this.url + this.privados.disableCard,
        // @ts-ignore
        null, {params: this.params}, this.httpOptions
      ).pipe(
        retry(1),
      );


  }

  enableCard(pan: string): Observable<any> {
    this.clearPath();
    this.params = new HttpParams()
      .set('pan', pan);
    // @ts-ignore
    return this.httpClient
      .put<any>(this.url + this.privados.enableCard,
        // @ts-ignore
        null, {params: this.params}, this.httpOptions
      ).pipe(
        retry(1),
      );
  }

  

  movements(desde?: string, hasta?: string): Observable<any> {
    this.clearPath();
    this.params = new HttpParams()
      .set('dateFrom', desde)
      .set('dateUp', hasta);
    // @ts-ignore
    return this.httpClient
      .get<any>(this.url + this.privados.movements,
        // @ts-ignore
     {params: this.params}, this.httpOptions
  ).pipe(
      retry(1),
    );
  }



  movie(limit?: number): Observable<any> {
    this.clearPath();
    this.path = '?limit=' + limit;
    return this.httpClient
      .get<any>(this.url + this.privados.training + this.path,
    this.httpOptions
  ).pipe(
      retry(1),
    );
  }


  paymentDynamicCode(pin): Observable<any> {
    this.clearPath();
    this.params = new HttpParams()
      .set('pin', pin);
    // @ts-ignore
    return this.httpClient
      .get<any>(this.url + this.privados.paymentDynamicCode,
        // @ts-ignore
        {params: this.params}, this.httpOptions
      ).pipe(
        retry(1),
      );
  }

  localNearby(latitud, longitud): Observable<any> {
    this.clearPath();
    this.params = new HttpParams()
      .set('latitude', latitud)
      .set('longitude', longitud);

    // @ts-ignore
    return this.httpClient
      .get<any>(this.url + this.privados.localNearby,
        // @ts-ignore
        {params: this.params}, this.httpOptions
      ).pipe(
        retry(1),
      );
  }


  localLastPayments(): Observable<any> {
    this.clearPath();
    return this.httpClient
      .get<any>(this.url + this.privados.localLastPayments ,
        this.httpOptions
      ).pipe(
        retry(1),
      );
  }


  localMcCode(mcCode): Observable<any> {
    this.clearPath();
    this.params = new HttpParams()
      .set('mcCode', mcCode);

    // @ts-ignore
    return this.httpClient
      .get<any>(this.url + this.privados.localMcCode,
        // @ts-ignore
        {params: this.params} , this.httpOptions
      ).pipe(
        retry(1),
      );
  }


  onlinePaymentTrade(payment: Payment): Observable<any> {
    return this.httpClient
      .post<any>(this.url + this.privados.onlinePaymentTrade,
        payment,   this.httpOptions
      )
      .pipe(
        retry(1),
      );
  }

  paymentRut(): Observable<any> {
    this.clearPath();
    return this.httpClient.get<any>(this.url + this.privados.paymentRut,
      this.httpOptions
    ).pipe(
      retry(1),
    );
  }

  saveAuditFirstAccess(email: string, phone: string, isAudit: any, careerYear: any): Observable<any> {
    this.clearPath();
    this.params = new HttpParams()
      .set('email', email)
      .set('phone', phone)
      .set('isAudit', isAudit)
      .set('careerYear', careerYear);
    // @ts-ignore
    return this.httpClient.post<any>
    (this.url + this.privados.saveAuditFirstAccess,
      // @ts-ignore
      null, {params: this.params}, this.httpOptions
    )
      .pipe(
        retry(1),
      );

  }

  redirectToCases(redirect :string) {
    const url = redirect;
    window.open(url, '_blank');
  }

  checkUser(token: string): Observable<any> {
    return this.httpClient
      .get<any>(
        this.url + this.privados.checkUser,
        {token},
        // @ts-ignore
        this.httpOptions
      )
      .pipe(
        retry(1),
      );
  }
}

