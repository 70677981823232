import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Articles } from '../../dto/Articles';
import { PublicService } from '../../service/public.service';
import { HeaderComponent } from '../../shared/footer/header/header.component';

declare var sliderNoticias: any;

@Component({
  selector: 'app-vida-sana',
  templateUrl: './vida-sana.component.html',
  styleUrls: ['./vida-sana.component.css']
})
export class VidaSanaComponent implements OnInit {
  @ViewChild(HeaderComponent, {static: true}) headerComponent: HeaderComponent;
  articles: Array<Articles> = [];
  localArticles: Articles[];

  titleError: string;
  messageError: string;


  constructor(private publicService: PublicService,
              private cd: ChangeDetectorRef) { }

  ngOnInit() {
    // tslint:disable-next-line:no-unused-expression
    this.headerComponent.menuActive = 'm_vida-sana';
    this.getArticles();
  }

  // tslint:disable-next-line:member-ordering
  contentPrintNews = false;
  functionStartNewsSilder() {
    this.contentPrintNews = true;
    // tslint:disable-next-line:no-unused-expression
    new sliderNoticias();
    this.cd.detectChanges();
  }

  getArticles() {
    this.publicService.articles(7).subscribe((data: any) => {
      this.localArticles = data;
      this.localArticles.sort(this.publicService.compareArticles);
      this.localArticles = this.localArticles.slice(0,3);
      this.localArticles.forEach(article => {
        article.landing = article.landing + '<span class=\'plus\'>+</span>';
        this.articles.push(article);
      });
      this.cd.markForCheck();
    }, error => {
      this.errorMessage(error);
    });
  }

  errorMessage(error) {
    if (error.titleError && error.messageError) {
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }

}
