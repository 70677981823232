import {Component, OnInit} from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import {PrivateService} from '../../../service/private.service';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import { AuthGuard } from '../../../guards/auth/auth.guard';


declare var closeLightBox: any;

@Component({
  selector: 'app-private-inactivity',
  templateUrl: './private-inactivity.component.html',
  styleUrls: ['./private-inactivity.component.css']
})
export class PrivateInactivityComponent implements OnInit {

  constructor(private userIdle: UserIdleService,
              private privateService: PrivateService,
              private authService: AuthGuard,
              private readonly router: Router) {

  }

  ngOnInit() {
  }

  cerrarLightBox(modal) {
    // tslint:disable-next-line:no-unused-expression
    new closeLightBox(modal);
  }

  restartInactivity(modal) {

    this.userIdle.resetTimer();
    let token: string;

    if (localStorage.getItem('access_token') === null) {
      token = null;
    } else {
      token = localStorage.getItem('access_token');
    }

    sessionStorage.setItem('urlToRedirect', this.router.url);

    this.cerrarLightBox(modal);
    this.router.navigate(['/connect/login']);

  }

  extracted(error) {
    // se invalida token y usuario por error y para afuera
    this.router.navigate(['/logout']);
  }

  logout() {
    this.userIdle.stopWatching();
    this.cerrarLightBox('video-0');
    this.cerrarLightBox('video-1');
    this.cerrarLightBox('video-2');
    this.cerrarLightBox('myModal');
    this.cerrarLightBox('myModalInactivity');
    this.cerrarLightBox('myModalError');
    this.authService.logout(true);

  }

}
