import { Component, OnInit, ViewChild } from '@angular/core';
import { PublicService } from '../../service/public.service';
import { Articles } from '../../dto/Articles';
import { HeaderComponent } from '../../shared/footer/header/header.component';
import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {
  @ViewChild(HeaderComponent, {static: true}) headerComponent: HeaderComponent;

  articles: Array<Articles> = [];
  localArticles: Articles[];
  currentUser: any;

  titleError: string;
  messageError: string;

  constructor(private publicService: PublicService,
              private sanitizer: DomSanitizer) {
    this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));

   }

  ngOnInit() {
    // tslint:disable-next-line:no-unused-expression
    this.headerComponent.menuActive = 'm_vida-sana';
    this.getArticles();
  }

  getArticles() {
    this.publicService.articles(20).subscribe((data: any) => {
      this.localArticles = data;
      this.localArticles.sort(this.publicService.compareArticles);
      this.localArticles.forEach(article => {
        article.landing = article.landing + '<span class=\'plus\'>+</span>';
        this.articles.push(article);
      });
    }, error => {
      this.errorMessage(error);
    });
  }

  errorMessage(error) {
    if (error.titleError && error.messageError) {
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }


}
