import { Component, OnInit, ViewChild, } from '@angular/core';
import { HeaderComponent } from '../../shared/footer/header/header.component';

@Component({
  selector: 'app-buscador-rut-no-beneficiario',
  templateUrl: './buscador-rut-no-beneficiario.component.html',
  styleUrls: ['./buscador-rut-no-beneficiario.component.css']
})
export class BuscadorRutNoBeneficiarioComponent implements OnInit {

  @ViewChild(HeaderComponent, {static: true}) headerComponent: HeaderComponent;
  currentUser: any;

  ngOnInit() {
    this.headerComponent.menuActive = 'm_buscador_rut_beneficiario';
  }

}
