import {Component, OnInit, ViewChild} from '@angular/core';
import {HeaderComponent} from '../../shared/footer/header/header.component';
import {ActivatedRoute, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {UserProfile, UserProfileInfo} from '../../models/common/user-profile';
import Swal from 'sweetalert2';
import {Subscription} from 'rxjs';
import {PrivateService} from '../../service/private.service';
import {environment} from '../../../environments/environment';
import { AuthGuard } from '../../guards/auth/auth.guard';




@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent  implements OnInit {
  @ViewChild(HeaderComponent, {static: true}) headerComponent: HeaderComponent;
  tokenJWT: any;
  urlBuscador = '';
  private subscriptions: Subscription[] = [];



  constructor(private route: ActivatedRoute,
                private cookieService: CookieService,
                private authGuard: AuthGuard,
                private router: Router,
                private privateService: PrivateService,
                private readonly activatedRoute: ActivatedRoute) {

        this.urlBuscador = environment.tradeSearchEngine;
  }
  loadingHeader = true;


  ngOnInit() {
    this.tokenJWT = sessionStorage.getItem('accessToken');
    this.privateService.checkUser(this.tokenJWT).subscribe(response => {
      if (!response || !response.isBeneficiary) {
        window.location.href = environment.connect.register;
      }
    });

    if (this.tokenJWT !== null && this.tokenJWT !== '' && this.tokenJWT !== 'null') {
      this.loadingHeader = true;

      this.subscriptions.push(this.privateService.getUserProfileConnect(this.tokenJWT).subscribe(response => {
            if (response.ok) {
              localStorage.setItem('access_token', this.tokenJWT);
              this.privateService.getUserCompaniesConnect(this.tokenJWT).subscribe( x => {

                if ( x.data.companies.length > 0 ) {

                  this.privateService.info().subscribe(value => {
                      const userLogged = new UserProfile();
                      userLogged.infoUser = new UserProfileInfo();
                      userLogged.infoUser.mail = response.data.contactInformation.emails[0];
                      userLogged.infoUser.name = response.data.firstName;
                      userLogged.infoUser.lastName = response.data.lastName;
                      userLogged.infoUser.mobile = response.data.contactInformation.cellphone;
                      userLogged.infoUser.id = response.data.uid;
                      userLogged.infoUser.nameIes = value.ies;
                      userLogged.infoUser.typeIes = value.typeIes;
                      userLogged.infoUser.careerId = value.careerId;
                      userLogged.infoUser.career = value.career;
                      userLogged.infoUser.iesId = value.iesId;
                      userLogged.infoUser.yearIncome = value.yearIncome;
                      userLogged.infoUser.paymentRutActivated = value.paymentRutActivated;
                      userLogged.infoUser.expirationDate = value.expirationDate;
                      this.privateService.setUserLoggedIn(userLogged);

                      if (sessionStorage.getItem("urlToRedirect")) {
                        const url = sessionStorage.getItem("urlToRedirect");
                        sessionStorage.removeItem("urlToRedirect");
                        this.router.navigateByUrl(url);
                      } else {
                        this.router.navigateByUrl('/home');
                      }
                    }, error => {
                     this.loadingHeader = false;
                      Swal.fire('Inicio Sesión', 'Los datos ingresados no coindicen con un beneficiario de Beca Junaeb Pluxee', 'error')
                        .then((result) => {
                          this.authGuard.logout(true);
                        });
                    }
                  );

                } else {
                  this.loadingHeader = false;
                  Swal.fire('Inicio Sesión', 'Los datos ingresados no coindicen con un beneficiario de Beca Junaeb Pluxee', 'error')
                    .then((result) => {
                      this.authGuard.logout(true);
                    });
                }

              }, error => {
                this.router.navigate(['/logout']);
              });
            } else {
              this.router.navigate(['/logout']);
            }

        }, error => {
          this.router.navigate(['/logout']);
        }
      ));


    } else {
      this.loadingHeader = false;
      this.router.navigate(['/logout']);
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
    }
  }

  extracted(error) {
    throw error;
  }

}
