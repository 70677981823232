import { Component, OnInit, ViewChild } from '@angular/core';
import { PublicService } from '../../service/public.service';
import { Articles } from '../../dto/Articles';

import { HeaderComponent } from '../../shared/footer/header/header.component';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  @ViewChild(HeaderComponent, {static: true}) headerComponent: HeaderComponent;
  articles: Array<Articles> = [];
  localArticles: Articles[];
  year: number;
  month: number;

  titleError: string;
  messageError: string;

  pdf: 'src/assets/documents/PDF_PASOAPASO_V2.pdf';

  constructor(private publicService: PublicService) {
    this.searchLastNewsLetter();
  }

  ngOnInit() {
    // tslint:disable-next-line:no-unused-expression
    this.headerComponent.menuActive = 'm_noticias';
  }

  searchLastNewsLetter() {
    this.publicService.searchLastNewsLetter(this.year, this.month).subscribe((data: Articles[]) => {
      console.log(data)
      this.localArticles = data;
      this.localArticles.forEach(article => {
        article.title = article.title.replace(/<h1>|<h2>|<h3>|<h4>|<\/h1>|<\/h2>|<\/h3>|<\/h4>/g,"");
        this.articles.push(article);
      });
    }, error => {
      this.errorMessage(error);
    });
  }

  errorMessage(error) {
    if (error.titleError && error.messageError) {
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }

}
