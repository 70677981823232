import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { HeaderComponent } from '../../shared/footer/header/header.component';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-buscador-rut-gracias',
  templateUrl: './buscador-rut-gracias.component.html',
  styleUrls: ['./buscador-rut-gracias.component.css']
})
export class BuscadorRutGraciasComponent implements OnInit {

  @ViewChild(HeaderComponent, {static: true}) headerComponent: HeaderComponent;
  currentUser: any;
  merchantSearcher: string;

  ngOnInit() {
    this.headerComponent.menuActive = 'm_buscador_rut_gracias';
    this.merchantSearcher = environment.tradeSearchEngine;
  }

}
