import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { IOption } from 'ng-select';
import { CurrentCard } from '../../../dto/CurrentCard';
import {PrivateService} from '../../../service/private.service';
import {Router} from '@angular/router';
import { AuthGuard } from '../../../guards/auth/auth.guard';



@Component({
  selector: 'app-private-help-center',
  templateUrl: './private-help-center.component.html',
  styleUrls: ['./private-help-center.component.css']
})
export class PrivateHelpCenterComponent implements OnInit {

  contactForm: FormGroup;
  submitted = true;
  success = false;
  loading = false;
  contactReason: Array<IOption> = [];
  currentUser: any;
  currentCard: CurrentCard;
  temp: Array<IOption> = [];

  titleError: string;
  messageError: string;

  constructor(private formBuilder: FormBuilder,
              private readonly router: Router,
              private authService: AuthGuard,
              private privateService: PrivateService) {

      this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));
      this.currentCard = JSON.parse(localStorage.getItem('currentCard'));

      this.contactForm = this.formBuilder.group({
        contactReason: new FormControl('', Validators.compose([Validators.required])),
        message: new FormControl('', Validators.compose([Validators.required]))
      });

  }

  ngOnInit() {
    this.getContactReason();
  }

  getContactReason() {
    this.temp = new Array<IOption>();
      this.privateService.contactReason().subscribe((data: any) => {
        let i = 0;
        data.forEach(d => {
          this.temp[i] = {label: d.reasonTitle, value: d.reasonTitle};
          i++;
        });
        this.contactReason = this.temp;
      }, error => {
        this.errorMessage(error);
      });
  }

  onSubmit() {
    if (this.contactForm.invalid) {
      this.submitted = false;
      return;
    }
    this.success = true;
    this.contactMail();
  }

  contactMail() {
    this.privateService.contactMail(this.contactForm.controls.contactReason.value,
                                     this.contactForm.controls.message.value,
                                     'contact').subscribe((data: any) => {
    }, error => {
      this.errorMessage(error);
    });
  }



  errorMessage(error) {
    if (error.titleError && error.messageError) {
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }

  logout() {
    this.authService.logout(false);
  }

}
