import {Component, OnInit, AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Subscription} from 'rxjs';
import {LoadingService} from '../../../service/loading/loading.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from '../../../guards/auth/auth.guard';
import {environment} from '../../../../environments/environment';
import { PrivateService } from '../../../service/private.service';


declare var startHeader: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent  implements OnInit, AfterViewInit {
  error = '';
  titleError: string;
  menuActive = '';
  loading = false;
  igvsd = '';
  wvah5sd = '';
  urlBuscador = '';
  url = '';
  loadingHeader = false;
  private subscriptions: Subscription[] = [];
  showLoader: boolean;



  constructor(
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private authGuard: AuthGuard,
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private privateService: PrivateService,
    private loaderService: LoadingService) {
    this.url = environment.tradeSearchEngine;
    this.urlBuscador = this.url;
  }


  ngAfterViewInit(): void {
    // tslint:disable-next-line:no-unused-expression
    new startHeader();
  }

  ngOnInit(): void {
    this.subscriptions.push(this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    }));
  }
  

}
