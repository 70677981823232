import { Component, OnInit, ViewChild } from '@angular/core';
import { PublicService } from '../../service/public.service';
import { MenuDetalle } from '../../dto/MenuDetalle';
import { Commerce } from 'src/app/dto/Commerce';
import { HeaderComponent } from '../../shared/footer/header/header.component';



@Component({
  selector: 'app-menu-saludable',
  templateUrl: './menu-saludable.component.html',
  styleUrls: ['./menu-saludable.component.css']
})
export class MenuSaludableComponent implements OnInit {
  @ViewChild(HeaderComponent, {static: true}) headerComponent: HeaderComponent;
  comercios: Array<Commerce> = [];
  menuDetalle: Array<MenuDetalle> = [];
  error = '';

  titleError: string;
  messageError: string;


  constructor(private publicService: PublicService) { }

  ngOnInit() {
    this.headerComponent.menuActive = 'm_menu-saludable';
    this.menus();
    this.detalleMenu();
  }


  menus() {
    this.publicService.menuFindAllCommerce().subscribe((data: any) => {
      this.comercios = data;
    }, error => {
      this.errorMessage(error);
    });
  }

  detalleMenu() {
    this.publicService.menuFindAll().subscribe((data: any) => {
      data.forEach(d => {
          this.menuDetalle.push(d);
      });
    }, error => {
      this.errorMessage(error);
    });
  }

  errorMessage(error) {
    if (error.titleError && error.messageError) {
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }


}
