import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnectService } from 'src/app/service/connect/connect.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private route: ActivatedRoute, private connectService: ConnectService) { }


  ngOnInit() {
    this.route.queryParams.subscribe(params => {

      const code = params['code'];
      sessionStorage.setItem('code', code);
      
      const state = params['state'];
      sessionStorage.setItem('state', state);

      this.connectService.tokenGenerate(code, state);
    });
  
  }

}
