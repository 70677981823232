import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { PublicService } from '../../../service/public.service';
import { Recipes } from '../../../dto/Recipes';
import { PrivateHeaderComponent } from '../private-header/private-header.component';
import { IOption } from 'ng-select';
import { CurrentCard } from '../../../dto/CurrentCard';
import {PrivateService} from '../../../service/private.service';
import {Router} from '@angular/router';
import { AuthGuard } from '../../../guards/auth/auth.guard';

declare var sliderRecetas: any;

@Component({
  selector: 'app-private-recipes',
  templateUrl: './private-recipes.component.html',
  styleUrls: ['./private-recipes.component.css']
})
export class PrivateRecipesComponent implements OnInit {

  @ViewChild(PrivateHeaderComponent, {static: true}) privateHeaderComponent: PrivateHeaderComponent;
  currentUser: any;
  currentCard: CurrentCard;
  localRecipes: Recipes[];
  recipes: Array<Recipes> = [];
  category: Array<string> = [];
  temp: Array<IOption> = [];
  cat = new Array<IOption>();

  titleError: string;
  messageError: string;

  constructor(private publicService: PublicService,
              private privateService: PrivateService,
              private authService: AuthGuard,
              private readonly router: Router,
              private cd: ChangeDetectorRef) {
    this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));
    this.currentCard = JSON.parse(localStorage.getItem('currentCard'));

    }

  ngOnInit() {
    this.privateHeaderComponent.menuActive = 'm_vida-sana';
    this.getRecipes();
  }


  // tslint:disable-next-line:member-ordering
  contentPrintRecipes = false;
  functionStartRecipes() {
    this.contentPrintRecipes = true;
    // tslint:disable-next-line:no-unused-expression
    new sliderRecetas();
    this.cd.detectChanges();
  }

  getRecipes(option?: IOption) {

    this.temp = new Array<IOption>();
    this.temp[0] = {label: 'Todas', value: 'Todas'};
    let i = 1;
    this.recipes = new Array<Recipes>();
    this.category = new Array<string>();

    this.publicService.recipes().subscribe((data: any) => {
      this.localRecipes = data;
      this.localRecipes.forEach(recipe => {
        let existe = false;
        this.category.forEach(c => {
          // tslint:disable-next-line:triple-equals
          if (c == recipe.category) {
            existe = true;
          }
        });

        if (!existe) {
          this.temp[i] = {label: recipe.category.trim(), value: recipe.category.trim()};
          i++;
          this.category.push(recipe.category);
        }

        // tslint:disable-next-line:triple-equals
        if (recipe.category.trim() == 'Desayuno') {
             recipe.categoryRecipe = 'recipe__item--starter';
          // tslint:disable-next-line:triple-equals
        } else if  (recipe.category.trim() == 'Plato de fondo') {
            recipe.categoryRecipe = 'recipe__item--main-course';
          // tslint:disable-next-line:triple-equals
             } else if  (recipe.category.trim() == 'Ensaladas') {
            recipe.categoryRecipe = 'recipe__item--dessert';
             } else {
            recipe.categoryRecipe = 'recipe__item--main-course';
             }

        this.cat = this.temp;
        // tslint:disable-next-line:triple-equals
        if ((option !== undefined && recipe.category.trim() == option.value) || (option === undefined || option.value == 'Todas')) {
            this.recipes.push(recipe);
        }
      });
      this.cd.markForCheck();
    }, error => {
      this.errorMessage(error);
    });
  }



  errorMessage(error) {
    if (error.titleError && error.messageError) {
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }

  logout() {
    this.authService.logout(false);
  }
}
