import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ConnectService } from 'src/app/service/connect/connect.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private connectService: ConnectService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    if (!this.isAuthorized()) {
      this.router.navigate(['/connect/login']);
    }

    return true;
  }


  private isAuthorized(): boolean {
    const state = sessionStorage.getItem('state');
    const code = sessionStorage.getItem('code');
  
    return state !== null && state !== undefined && state !== '' &&
           code !== null && code !== undefined && code !== '';
  }
  
  logout(silentLogout: boolean) {
    window.location.href = this.connectService.generateUrlLogout(silentLogout);
  }
}
