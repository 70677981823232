import { FormControl, AbstractControl } from "@angular/forms";



/** A hero's name can't match the given regular expression */
export function validateRut(control:AbstractControl) {
    if (control && (control.value !==null || control.value !== undefined)){

        let texto = quitarFormato(control.value);
        let largo = texto.length;
        // rut muy corto
        if ( largo < 2 ) {
            return {
                invalidRut: true
            };
        }

        // verifica que los numeros correspondan a los de rut
        for (let i=0; i < largo ; i++ ){
            // numero o letra que no corresponda a los del rut
            if(!digitoValido(texto.charAt(i))){
                return {
                    invalidRut: true
                };
            }
        }

        var invertido = "";
        for(let i=(largo-1),j=0; i>=0; i--,j++){
            invertido = invertido + texto.charAt(i);
        }
        var dtexto = "";
        dtexto = dtexto + invertido.charAt(0);
        dtexto = dtexto + '-';
        let cnt = 0;

        for (let i=1,j=2; i<largo; i++,j++ ) {
            if ( cnt == 3 ){
                dtexto = dtexto + '.';
                j++;
                dtexto = dtexto + invertido.charAt(i);
                cnt = 1;
            }
            else {
                dtexto = dtexto + invertido.charAt(i);
                cnt++;
            }
        }

        invertido = "";
        for (let i=(dtexto.length-1),j=0; i>=0; i--,j++){
            invertido = invertido + dtexto.charAt(i);
        }

        if (digitoCorrecto(texto)){
            return null;
        }
        return {
            invalidRut: true
        };
    }
  }

export function quitarFormato(rut){
        let strRut = rut;
        while( strRut.indexOf('.') != -1 ){
            strRut = strRut.replace('.','');
        }
        while( strRut.indexOf('-') != -1 ){
          strRut = strRut.replace("-",'');
        }
        return strRut.toUpperCase();
    }

    function digitoValido(dv){
        if ( dv != '0' && dv != '1' && dv != '2' && dv != '3' && dv != '4'
        && dv != '5' && dv != '6' && dv != '7' && dv != '8' && dv != '9'
        && dv != 'k'  && dv != 'K'){
            return false;
        }
        return true;
    }

    function digitoCorrecto(crut){
        let largo = crut.length;
        let rut;
        if ( largo < 2 ){
            return false;
        }
        if(largo > 2){
            rut = crut.substring(0, largo - 1);
        }
        else{
            rut = crut.charAt(0);
        }
        let dv = crut.charAt(largo-1);
        digitoValido(dv);

        if(rut == null || dv == null){
            return 0;
        }
        let dvr = getDigito(rut);
        if (dvr != dv.toLowerCase()){
            return false;
        }
        return true;
    }
    function getDigito(rut){
        var dvr = '0';
        let suma = 0;
        let mul  = 2;

        for(let i=rut.length-1;i>= 0;i--){

            suma = suma + rut.charAt(i) * mul;
            if (mul == 7){
                mul = 2;
            }
            else{
                mul++;
            }
        }
        let res = suma % 11;

        if (res==1){
            return 'k';
        }
        else if(res==0){
            return '0';
        }
        else{
            return 11-res;
        }
    }


export function formatearRut(Rut)
    {
        let sRut = Rut.trim();
        let sRutFormateado = '';
        sRut = quitarFormato(sRut);
        let digitoVerificador = getDigito(sRut);

        if(digitoVerificador){
        var sDV = sRut.charAt(sRut.length-1);
        sRut = sRut.substring(0, sRut.length-1);
        }
        while( sRut.length > 3 )
        {
        sRutFormateado = "." + sRut.substr(sRut.length - 3) + sRutFormateado;
        sRut = sRut.substring(0, sRut.length - 3);
        }
        sRutFormateado = sRut + sRutFormateado;
        if(sRutFormateado != "" && digitoVerificador)
        {
        sRutFormateado += "-"+sDV;
        }
        else if(digitoVerificador)
        {
        sRutFormateado += sDV;
        }

        return sRutFormateado;
    }

    export function formateaRut(rut) {

        var actual = rut.replace(/^0+/, "");
        if (actual != '' && actual.length > 1) {
            var sinPuntos = actual.replace(/\./g, "");
            var actualLimpio = sinPuntos.replace(/-/g, "");
            var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
            var rutPuntos = "";
            var i = 0;
            var j = 1;
            for (i = inicio.length - 1; i >= 0; i--) {
                var letra = inicio.charAt(i);
                rutPuntos = letra + rutPuntos;
                if (j % 3 == 0 && j <= inicio.length - 1) {
                    rutPuntos = "." + rutPuntos;
                }
                j++;
            }
            var dv = actualLimpio.substring(actualLimpio.length - 1);
            rutPuntos = rutPuntos + "-" + dv;
        }
        return rutPuntos;
    }
