export class CardHeader {
  // tslint:disable-next-line:indent
	'pan': string;
  // tslint:disable-next-line:indent
	'lastRechargeDate': string;
  // tslint:disable-next-line:indent
	'lastRechargeAmount': string;
  // tslint:disable-next-line:indent
	'lastMovementAmount': string;
  // tslint:disable-next-line:indent
	'lastRechargeExpirateDate': string;
  // tslint:disable-next-line:indent
	'currentAmount': string;
  // tslint:disable-next-line:indent
	'expireAmount': string;
  // tslint:disable-next-line:indent
	'lastExpireAmountDate': string;
}
