import {Component, OnInit, ViewChild} from '@angular/core';
import { UserData } from '../../../dto/UserData';
import { Router } from '@angular/router';
import {FormBuilder } from '@angular/forms';
import { PrivateService } from '../../../service/private.service';
import { CurrentCard } from '../../../dto/CurrentCard';
import {environment} from '../../../../environments/environment';
import {PrivateHeaderComponent} from '../private-header/private-header.component';
import { AuthGuard } from '../../../guards/auth/auth.guard';

@Component({
  selector: 'app-private-mis-datos',
  templateUrl: './private-mis-datos.component.html',
  styleUrls: ['./private-mis-datos.component.css']
})
export class PrivateMisDatosComponent implements OnInit {
  @ViewChild(PrivateHeaderComponent, {static: true}) privateHeaderComponent: PrivateHeaderComponent;
  userData: UserData;
  currentUser: any;
  currentCard: CurrentCard;
  messageError = '';
  ciamMiCuenta = '';
  ciamPinCompra = '';


  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private authService: AuthGuard) {

    this.ciamMiCuenta = environment.connect.account;

    this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));
    this.currentCard = JSON.parse(localStorage.getItem('currentCard'));

    this.ciamPinCompra =  this.generateUrlChangePin(this.currentCard.pan);
  }

  ngOnInit() {
    this.userData = new UserData();
    this.userData.name = this.currentUser.infoUser.name;
    this.userData.lastName = this.currentUser.infoUser.lastName;

    this.userData.email = this.currentUser.infoUser.mail;
    // tslint:disable-next-line:triple-equals
    if (this.currentUser.infoUser.mobile != null &&  this.currentUser.infoUser.mobile != '') {
      this.userData.phone = this.currentUser.infoUser.mobile.replace(/ /g, '');
    }

    this.userData.ies = this.currentUser.infoUser.nameIes;
    this.userData.career = this.currentUser.infoUser.career;
    this.userData.typeIes = this.currentUser.infoUser.typeIes;
    this.userData.yearIncome = this.currentUser.infoUser.yearIncome;
  }

logout() {
  this.authService.logout(false);
}

  generateUrlChangePin(currentCard: String): string {
    let url = new URL(environment.connect.changePin);
    url.searchParams.append("card", "1" + currentCard.substring(8));
    url.searchParams.append("pin", "new");

    return url.href;
  }
}
