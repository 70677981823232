import { Component, OnInit, ViewChild } from '@angular/core';
import { PublicService } from '../../service/public.service';
import { Articles } from '../../dto/Articles';
import { ActivatedRoute } from '@angular/router';
import { HeaderComponent } from '../../shared/footer/header/header.component';



@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})
export class NewsDetailComponent implements OnInit {

  @ViewChild(HeaderComponent, {static: true}) headerComponent: HeaderComponent;
  article: Articles;

  titleError: string;
  messageError: string;

  constructor(private publicService: PublicService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    // tslint:disable-next-line:no-unused-expression
    this.findArticle(this.route.snapshot.paramMap.get('idName'));
    this.headerComponent.menuActive = 'm_noticias';
  }

  findArticle(idName: string) {
    this.publicService.searchArticle(idName).subscribe((data: any) => {
      this.article = data;
      this.article.title = '<small>' + this.article.createdDate + '</small>' + this.article.title;
    }, error => {
      this.errorMessage(error);
    });
  }

  errorMessage(error) {
    if (error.titleError && error.messageError) {
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }
}
