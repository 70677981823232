import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// tslint:disable-next-line:import-spacing
import { ReactiveFormsModule }    from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { SelectModule } from 'ng-select';
import { TooltipModule} from 'ng2-tooltip-directive';
import { QRCodeModule } from 'angularx-qrcode';
import { UserIdleModule } from 'angular-user-idle';
import { Ng2Rut } from 'ng2-rut';
import {NgxYoutubePlayerModule} from 'ngx-youtube-player';



/*COMPONET*/
import { AppComponent } from './app.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HelpCenterComponent } from './components/help-center/help-center.component';
import { IndexComponent } from './index/index.component';
import { SobreLaBecaComponent } from './components/sobre-la-beca/sobre-la-beca.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { ArticlesDetailComponent } from './components/articles-detail/articles-detail.component';
import { FaqComponent } from './components/faq/faq.component';
import { GoodPracticesComponent } from './components/good-practices/good-practices.component';
import { PrivateHeaderComponent } from './components/private-components/private-header/private-header.component';
import { PrivateMisDatosComponent } from './components/private-components/private-mis-datos/private-mis-datos.component';
import { NewsComponent } from './components/news/news.component';
import { NewsDetailComponent } from './components/news-detail/news-detail.component';
import { PrivateNewsComponent } from './components/private-components/private-news/private-news.component';
import { PrivateNewsDetailComponent } from './components/private-components/private-news-detail/private-news-detail.component';
import { PrivateHelpCenterComponent } from './components/private-components/private-help-center/private-help-center.component';
import { VidaSanaComponent } from './components/vida-sana/vida-sana.component';
import { PrivateVidaSanaComponent } from './components/private-components/private-vida-sana/private-vida-sana.component';
import { PrivateArticlesDetailComponent } from './components/private-components/private-articles-detail/private-articles-detail.component';
import { PrivateArticlesComponent } from './components/private-components/private-articles/private-articles.component';
import { MenuSaludableComponent } from './components/menu-saludable/menu-saludable.component';
import { PrivateMyCardComponent } from './components/private-components/private-my-card/private-my-card.component';
import { PrivateCardRequestComponent } from './components/private-components/private-card-request/private-card-request.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale } from 'ngx-bootstrap/locale';
import { NgxCaptchaModule } from 'ngx-captcha';
import { PrivateRecipesComponent } from './components/private-components/private-recipes/private-recipes.component';
import { PrivateRecipesDetailComponent } from './components/private-components/private-recipes-detail/private-recipes-detail.component';
import { PrivateTrainingComponent } from './components/private-components/private-training/private-training.component';
import { PrivatePaymentComponent } from './components/private-components/private-payment/private-payment.component';
import { PrivatePaymentDynamicCodeComponent } from './components/private-components/private-payment-dynamic-code/private-payment-dynamic-code.component';
import { PrivateFaqComponent } from './components/private-components/private-faq/private-faq.component';
import { BuscadorRutGraciasComponent } from './components/buscador-rut-gracias/buscador-rut-gracias.component';
import {BuscadorRutBeneficiarioComponent} from './components/buscador-rut-beneficiario/buscador-rut-beneficiario.component';
import {BuscadorRutNoBeneficiarioComponent} from './components/buscador-rut-no-beneficiario/buscador-rut-no-beneficiario.component';
import {BuscadorRutComponent} from './components/buscador-rut/buscador-rut.component';

/*SERVICE*/
import { PublicService } from './service/public.service';
import { PrivateService } from './service/private.service';
import { PrivateHomeComponent } from './components/private-components/private-home/private-home.component';
import { PrivateOnlinePaymentComponent } from './components/private-components/private-online-payment/private-online-payment.component';
import { PrivateInactivityComponent } from './components/private-components/private-inactivity/private-inactivity.component';
import { environment } from '../environments/environment';
import {Privados, Publicos} from './service/endpoints';
import {HeaderComponent} from './shared/footer/header/header.component';
import {AuthGuard} from './guards/auth/auth.guard';
import { LoadingComponent } from './components/loading/loading.component';
import {httpInterceptorProviders} from './interceptors';
import {CookieService} from 'ngx-cookie-service';
import { PrivateSobreLaBecaComponent } from './components/private-components/private-sobre-la-beca/private-sobre-la-beca.component';
import { PrivateGoodPracticesComponent } from './components/private-components/private-good-practices/private-good-practices.component';
import { CentroDeAyudaComponent } from './components/centro-de-ayuda/centro-de-ayuda.component';
import { ConnectLoginComponent } from './components/login/connect-login/connect-login.component';
import { ConnectService } from './service/connect/connect.service';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { AuthInterceptor } from './interceptors/auth/auth-interceptor';


defineLocale('es-us', deLocale);

const routes: Route[] = [
  {path: '', component: IndexComponent, pathMatch: 'full' },
  {path: 'loading', component: LoadingComponent},
  {path: 'outside', redirectTo: '', pathMatch: 'full' },
  {path: 'sobre-la-beca', component: SobreLaBecaComponent},
  {path: 'buenas-practicas', component: GoodPracticesComponent},
  {path: 'preguntas-frecuentes', component: FaqComponent},
  {path: 'centro-de-ayuda', component: HelpCenterComponent},
  {path: 'vivir-bien', component: AppComponent},
  {path: 'noticias', component: NewsComponent},
  {path: 'noticia/:idName', component: NewsDetailComponent, runGuardsAndResolvers: 'always' },
  {path: 'vida-sana', component: VidaSanaComponent},
  {path: 'menu-saludable', component: MenuSaludableComponent},
  {path: 'articulos', component: ArticlesComponent},
  {path: 'articulo/:idName', component: ArticlesDetailComponent, runGuardsAndResolvers: 'always' },
  {path: 'buscador-rut-gracias', component: BuscadorRutGraciasComponent},
  {path: 'buscador-rut-beneficiario', component: BuscadorRutBeneficiarioComponent},
  {path: 'buscador-rut-no-beneficiario', component: BuscadorRutNoBeneficiarioComponent},
  {path: 'buscador-rut', component: BuscadorRutComponent},
  {path: 'connect/login', component: ConnectLoginComponent, pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'logout', component: LogoutComponent},

  /*privado*/
  {path: 'home', component: PrivateHomeComponent, canActivate: [AuthGuard] },
  {path: 'mis-datos', component: PrivateMisDatosComponent, canActivate: [AuthGuard] },
  {path: 'mi-tarjeta', component: PrivateMyCardComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'},
  {path: 'mi-tarjeta/:bloque', component: PrivateMyCardComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'},
  {path: 'capacitacion', component: PrivateTrainingComponent, canActivate: [AuthGuard]},
  {path: 'pago', component: PrivatePaymentComponent, canActivate: [AuthGuard]},
  {path: 'pago-clave-dinamica', component: PrivatePaymentDynamicCodeComponent, canActivate: [AuthGuard]},
  {path: 'pago-online', component: PrivateOnlinePaymentComponent, canActivate: [AuthGuard]},

  {path: 'menu-saludable', component: AppComponent},
  {path: 'noticias-privadas', component: PrivateNewsComponent, canActivate: [AuthGuard]},
  {path: 'noticia-privada/:idName', component: PrivateNewsDetailComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always'},
  {path: 'centro-de-ayuda-privado', component: PrivateHelpCenterComponent, canActivate: [AuthGuard]},
  {path: 'vida-sana-privado', component: PrivateVidaSanaComponent, canActivate: [AuthGuard]},

  {path: 'articulo-privado', component: PrivateArticlesComponent, canActivate: [AuthGuard]},
  {path: 'articulo-privado/:idName', component: PrivateArticlesDetailComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },

  {path: 'recetas', component: PrivateRecipesComponent, canActivate: [AuthGuard]},
  {path: 'recetas/:idName', component: PrivateRecipesDetailComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },

  {path: 'preguntas-frecuentes-privado', component: PrivateFaqComponent, canActivate: [AuthGuard]},
  {path: 'sobre-la-beca-privado', component: PrivateSobreLaBecaComponent,  canActivate: [AuthGuard]},
  {path: 'buenas-practicas-privado', component: PrivateGoodPracticesComponent,  canActivate: [AuthGuard]},

  {path: '**', redirectTo: ''}

];


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HelpCenterComponent,
    IndexComponent,
    SobreLaBecaComponent,
    PrivateHomeComponent,
    FaqComponent,
    GoodPracticesComponent,
    PrivateHeaderComponent,
    PrivateMisDatosComponent,
    NewsComponent,
    NewsDetailComponent,
    PrivateNewsComponent,
    PrivateNewsDetailComponent,
    PrivateHelpCenterComponent,
    VidaSanaComponent,
    PrivateVidaSanaComponent,
    PrivateArticlesComponent,
    PrivateArticlesDetailComponent,
    MenuSaludableComponent,
    PrivateMyCardComponent,
    PrivateCardRequestComponent,
    ArticlesDetailComponent,
    ArticlesComponent,
    PrivateRecipesComponent,
    PrivateRecipesDetailComponent,
    PrivateTrainingComponent,
    PrivatePaymentComponent,
    PrivatePaymentDynamicCodeComponent,
    PrivateFaqComponent,
    PrivateOnlinePaymentComponent,
    PrivateInactivityComponent,
    HeaderComponent,
    LoadingComponent,
    BuscadorRutGraciasComponent,
    BuscadorRutBeneficiarioComponent,
    BuscadorRutNoBeneficiarioComponent,
    BuscadorRutComponent,
    PrivateSobreLaBecaComponent,
    PrivateGoodPracticesComponent,
    CentroDeAyudaComponent,
    ConnectLoginComponent,
    LoginComponent,
    LogoutComponent,

  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes,
      {
        useHash: false, onSameUrlNavigation: 'reload',
        scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'
      }),
    HttpClientModule,
    ReactiveFormsModule,
    TooltipModule,
    FormsModule,
    SelectModule,
    BsDatepickerModule.forRoot(),
    NgxYoutubePlayerModule.forRoot(),
    QRCodeModule,
    NgxCaptchaModule,
    Ng2Rut,
    //  Opcionalmente, puede establecer el tiempo para `idle`,` timeout` y `ping` en segundos.
    //  Valores predeterminados: `idle` es 600 (10 minutos),` timeout` es 300 (5 minutos)
    //  y `ping` es 120 (2 minutos).
    UserIdleModule.forRoot({idle: 300, timeout: 310, ping: 310})
  ],
  providers: [{provide: APP_BASE_HREF, useValue: environment.base},  [CookieService]  , PublicService, PrivateService, ConnectService,
  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
     Privados, Publicos,
    httpInterceptorProviders,

  ],
  exports: [
    HeaderComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

