import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { PrivateHeaderComponent } from '../private-header/private-header.component';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { PrivateService } from '../../../service/private.service';
import { PublicService } from '../../../service/public.service';
import { Articles } from '../../../dto/Articles';
import { Recipes } from '../../../dto/Recipes';
import { CurrentCard } from '../../../dto/CurrentCard';
import { ErrorData } from '../../../dto/ErrorData';
import {Router} from '@angular/router';
import { AuthGuard } from '../../../guards/auth/auth.guard';

declare var sliderArticulos: any;
declare var sliderRecetas: any;


@Component({
  selector: 'app-private-vida-sana',
  templateUrl: './private-vida-sana.component.html',
  styleUrls: ['./private-vida-sana.component.css']
})
export class PrivateVidaSanaComponent implements OnInit {

  @ViewChild(PrivateHeaderComponent, {static: true}) privateHeaderComponent: PrivateHeaderComponent;
  currentUser: any;
  currentCard: CurrentCard;
  contactForm: FormGroup;
  success = false;
  submitted = true;
  articles: Array<Articles> = [];
  localArticles: Articles[];
  localRecipes: Recipes[];
  recipes: Array<Recipes> = [];

  titleError: string;
  messageError: string;

  constructor(private formBuilder: FormBuilder,
              private publicService: PublicService,
              private readonly router: Router,
              private authService: AuthGuard,
              private privateService: PrivateService,
              private cd: ChangeDetectorRef) {
      this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));
      this.currentCard = JSON.parse(localStorage.getItem('currentCard'));
      this.contactForm = this.formBuilder.group({
        consulta: new FormControl('', Validators.compose([Validators.required])),
        mensaje: new FormControl('', Validators.compose([Validators.required])),
      });

  }

  ngOnInit() {
    this.privateHeaderComponent.menuActive = 'm_vida-sana';
    this.getArticles();
    this.getRecipes();
  }

  // tslint:disable-next-line:member-ordering
  contentPrintArticles = false;
  functionStartArticlesSilder() {
    this.contentPrintArticles = true;
    // tslint:disable-next-line:no-unused-expression
    new sliderArticulos();
    this.cd.detectChanges();
  }

  getArticles() {
    this.publicService.articles(7).subscribe((data: any) => {
      this.localArticles = data;
      this.localArticles.sort(this.publicService.compareArticles);
      this.localArticles = this.localArticles.slice(0,3);
      // tslint:disable-next-line:triple-equals
      if (this.localArticles.length == 0) {
        const error: ErrorData = new ErrorData();
        error.id = '100';
        error.messageError = 'No existen articulos';
        error.titleError = 'Sin articulos';
        this.errorMessage(error);
      } else {
        this.localArticles.forEach(article => {
          article.landing = article.landing + '<span class=\'plus\'>+</span>';
          this.articles.push(article);
        });
      }
      this.cd.markForCheck();
    }, error => {
      this.errorMessage(error);
    });
  }

  // tslint:disable-next-line:member-ordering
  contentPrintRecipes = false;
  functionStartRecipes() {
    this.contentPrintRecipes = true;
    // tslint:disable-next-line:no-unused-expression
    new sliderRecetas();
    this.cd.detectChanges();
  }

  getRecipes() {
    this.publicService.recipes().subscribe((data: any) => {
      this.localRecipes = data;
      // tslint:disable-next-line:triple-equals
      if (this.localRecipes.length == 0) {
        const error: ErrorData = new ErrorData();
        error.id = '100';
        error.messageError = 'No existen recetas';
        error.titleError = 'Sin recetas';
        this.errorMessage(error);
      } else {
        this.localRecipes.forEach(recipe => {
          recipe.preparing = recipe.preparing.replace(/<[^>]*>/g, '').substring(0, 200) + '...';
          this.recipes.push(recipe);
        });
      }
      this.cd.markForCheck();
    }, error => {
      this.errorMessage(error);
    });
  }

  onSubmit() {
    if (this.contactForm.invalid) {
      this.submitted = false;
      return;
    }
    this.success = true;
    this.saveAuditExpert();
    this.contactMail();
  }

  contactMail() {
    this.privateService.contactMail(
                                     this.contactForm.controls.consulta.value,
                                     this.contactForm.controls.mensaje.value,
                                     'expert').subscribe((data: any) => {
    }, error => {
      this.errorMessage(error);
    });
  }

  saveAuditExpert() {
    this.privateService.saveAuditExpert(this.currentUser.infoUser.name + ' ' + this.currentUser.infoUser.lastName,
                                        this.currentUser.infoUser.mail,
                                        this.currentUser.infoUser.nameIes,
                                        this.currentUser.infoUser.iesId,
                                        this.contactForm.controls.consulta.value).subscribe((data: any) => {
    }, error => {
      this.errorMessage(error);
    });
  }



  errorMessage(error) {
    if (error.titleError && error.messageError) {
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }


  logout() {
    this.authService.logout(false);
  }

}
