import { Component, OnInit, ViewChild } from '@angular/core';
import { Articles } from '../../dto/Articles';
import { PublicService } from '../../service/public.service';
import { ActivatedRoute } from '@angular/router';
import { HeaderComponent } from '../../shared/footer/header/header.component';


@Component({
  selector: 'app-articles-detail',
  templateUrl: './articles-detail.component.html',
  styleUrls: ['./articles-detail.component.css']
})
export class ArticlesDetailComponent implements OnInit {
  @ViewChild(HeaderComponent, {static: true}) headerComponent: HeaderComponent;
  currentUser: any;
  article: Articles;
  private sub: any;

  titleError: string;
  messageError: string;


  constructor(private publicService: PublicService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    // tslint:disable-next-line:no-unused-expression
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.sub = this.route.params.subscribe(params => {
      const id = params['idName'];
      this.findArticle(id);
   }, error => {
      this.errorMessage(error);
    });
    this.headerComponent.menuActive = 'm_vida-sana';
  }

  findArticle(idName: string) {
    this.publicService.searchArticle(idName).subscribe((data: any) => {
      this.article = data;
    }, error => {
      this.errorMessage(error);
    });
  }


  errorMessage(error) {
    if (error.titleError && error.messageError) {
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }

}
