import { Component, OnInit } from '@angular/core';
import { PrivateService } from 'src/app/service/private.service';
import { RandomCode } from 'src/app/dto/RandomCode';
import { ViewChild } from '@angular/core';
import { PrivateHeaderComponent } from 'src/app/components/private-components/private-header/private-header.component';
import { CurrentCard } from '../../../dto/CurrentCard';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { environment } from 'src/environments/environment';
import {Router} from '@angular/router';
import {toHex} from '../../../security/helpers/custom.validator';
import Swal from 'sweetalert2';
import { AuthGuard } from '../../../guards/auth/auth.guard';



@Component({
  selector: 'app-private-payment-dynamic-code',
  templateUrl: './private-payment-dynamic-code.component.html',
  styleUrls: ['./private-payment-dynamic-code.component.css']
})
export class PrivatePaymentDynamicCodeComponent implements OnInit {
  @ViewChild(PrivateHeaderComponent, {static: true}) privateHeaderComponent: PrivateHeaderComponent;
  currentUser: any;
  currentCard: CurrentCard;
  randomCode: RandomCode;
  loading: boolean;
  ms: number;
  minutos: number;
  segundos: number;
  dataTimer: string;
  error: string;
  getDinamicKey = true;
  filter = true;
  pin: string;


  constructor( private privateService: PrivateService,
               private readonly router: Router,
               private authService: AuthGuard,
              private reCaptchaV3Service: ReCaptchaV3Service) {
    this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));
    this.currentCard = JSON.parse(localStorage.getItem('currentCard'));
  }

  ngOnInit() {
    this.privateHeaderComponent.menuActive = 'm_pago';
  }

  getDynamicCode() {

    if (this.pin == null) {
      this.loading = false;
      Swal.fire('PIN de Compra', 'Por favor ingresa tu PIN de compra', 'info');
    } else {
      this.reCaptchaV3Service.execute(environment.captcha.siteKey, 'dynamicCode', (token) => {
        this.loading = true;
        this.getDinamicKey = true;
        this.error = null;
        this.privateService.paymentDynamicCode(toHex(this.pin)).subscribe((data: any) => {

          this.randomCode = data;

          if (this.randomCode.paymentCode != null) {

            // tslint:disable-next-line:triple-equals
            if (this.randomCode.paymentCode != '0') {
              this.ms = data.milliReload;
              // tslint:disable-next-line:no-bitwise
              this.minutos = Math.floor((data.milliReload / 1000 / 60) << 0);
              this.segundos = Math.floor((data.milliReload / 1000) % 60);
              this.countDownMin();
            } else {
              this.error =  this.capitalizeFirstLetter(this.randomCode.responseMessage);
              this.filter = true;
            }

          } else {

            if (this.randomCode.messageError != null ) {
              this.error =  this.randomCode.messageError;
              Swal.fire('PIN de Compra', this.randomCode.messageError, 'info');
            } else {
              this.error = 'No puedes crear clave dinámica, por favor revisa que tu tarjeta esté activa';
              Swal.fire('PIN de Compra', 'No puedes crear clave dinámica, por favor revisa que tu tarjeta esté activa', 'info');
            }

            this.filter = true;
          }

          this.loading = false;
          this.getDinamicKey = false;

          if (this. error == null) {
            this.filter = false;
          }

        }, error => {
          this.error =  'Error interno con el servidor';
          this.filter = true;
          this.loading = false;
        });
      });
    }



  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
  countDownMin() {
    if (this.minutos > 0) {
      this.dataTimer = this.minutos + ' minutos';
      // tslint:disable-next-line:prefer-const
      let intervalMin = setInterval(() => {
        this.minutos--;
        this.dataTimer = this.minutos + ' minutos';
        if (this.minutos <= 0) {
            clearInterval(intervalMin);
            this.segundos = 59;
            this.countDownSec();
        }
      }, 60000);
    } else {
      this.countDownSec();
    }
  }

  countDownSec() {
    this.dataTimer = this.segundos + ' segundos';
    const interval = setInterval(() => {
      this.dataTimer = this.segundos + ' segundos';
      this.segundos--;
      if (this.segundos < 0 ) {
        clearInterval(interval);
        this.getDynamicCode();
      }
    }, 1000);
  }


  logout() {
    this.authService.logout(false);
  }

}
