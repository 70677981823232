import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { HeaderComponent } from '../../shared/footer/header/header.component';
import { Router} from '@angular/router';
import {environment} from "../../../environments/environment";


@Component({
  selector: 'app-buscador-rut-beneficiario',
  templateUrl: './buscador-rut-beneficiario.component.html',
  styleUrls: ['./buscador-rut-beneficiario.component.css']
})
export class BuscadorRutBeneficiarioComponent implements OnInit {

 // private readonly router: Router;

  @ViewChild(HeaderComponent, {static: true}) headerComponent: HeaderComponent;
  currentUser: any;

  constructor(
    private router: Router,){}

  ngOnInit() {
    this.headerComponent.menuActive = 'm_buscador_rut_beneficiario';
  }

}
