import { Component, OnInit } from '@angular/core';
import { ConnectService } from '../../../service/connect/connect.service';

@Component({
  selector: 'app-connect-login',
  templateUrl: './connect-login.component.html',
  styleUrls: ['./connect-login.component.css']
})
export class ConnectLoginComponent implements OnInit {

  constructor(private connect: ConnectService) {}

  ngOnInit() {
    window.location.href = this.connect.generateUrlAuth();
  }

}