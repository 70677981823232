import { Injectable } from '@angular/core';
import { SHA256 } from 'crypto-js';
import * as CryptoJS from 'crypto-js';
import {environment} from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TokenConnectResponse } from 'src/app/models/common/TokenConnectResponse';

@Injectable({
  providedIn: 'root'
})
export class ConnectService {

  constructor(private http: HttpClient, private readonly router: Router, private cookie: CookieService) { }

  public generateUrlAuth(): string {
    let codeVer = this.generateCodeVerifier(43);
    let code = this.codeChallenge(codeVer);
    let state = this.codeChallenge(this.generateCodeVerifier(32));

    let url = new URL(environment.connect.authUrl);
    url.searchParams.append("client_id", environment.connect.clientId);
    url.searchParams.append("response_type", "code");
    url.searchParams.append("redirect_uri", environment.connect.redirectAuthUrl);
    url.searchParams.append("code_challenge", code);
    url.searchParams.append("code_challenge_method", "S256");
    url.searchParams.append("state", state);
    url.searchParams.append("resource", environment.connect.resource);
    url.searchParams.append("scope", "openid email phone profile");
    url.searchParams.append("prompt", "select_account")

    sessionStorage.setItem("codeVerifier", codeVer);
    
    return url.href;

  }

  public tokenGenerate(code: string, state: string): void {
    const formData = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('code', code)
      .set('redirect_uri', environment.connect.redirectAuthUrl)
      .set('code_verifier', sessionStorage.getItem('codeVerifier'))
      .set('client_id', environment.connect.clientId)
      .set('resource', environment.connect.resource);

    const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      });

    this.http
      .post<TokenConnectResponse>(environment.connect.tokenUrl, formData.toString(), { headers: headers })
      .subscribe({
        next: responseToken => {
          const { id_token, refresh_token, expires_in, access_token } = responseToken;
          let currentDate = new Date();
          currentDate.setSeconds(currentDate.getSeconds() + 600);
          sessionStorage.setItem('refreshToken', refresh_token);
          sessionStorage.setItem('expire', expires_in.toString());
          sessionStorage.setItem('idToken', id_token);
          sessionStorage.setItem('accessToken', access_token);
          localStorage.setItem('tokenExpireTime', (currentDate.getTime() / 1000).toString());

          this.router.navigate(['/loading']);
        },

        error: error => {
          console.error('Ha ocurrido un error: ', error.message);

          this.router.navigate(['/logout']);
        }
      });
  }

  public generateUrlLogout(silentLogout: boolean): string {
    let state = this.codeChallenge(this.generateCodeVerifier(32));

    let url = new URL(environment.connect.logoutUrl);
    url.searchParams.append("id_token_hint", sessionStorage.getItem('idToken'));
    url.searchParams.append("post_logout_redirect_uri", environment.connect.redirectLogoutUrl);
    url.searchParams.append("state", state);
    url.searchParams.append("silent_logout", silentLogout.toString());
    
    return url.href;

  }

  private generateCodeVerifier(codeLenght: number): string {
    return new CryptoJS.lib.WordArray.random(codeLenght).toString(CryptoJS.enc.Base64url)
      .replace(/=/g, "")
      .replace(/\+/g, "-")
      .replace(/\//g, "_");
  }


  private codeChallenge(varString: string) {

    let varReturn = SHA256(varString).toString(CryptoJS.enc.Base64url);
    return varReturn;
  }
}
