import { Component, OnInit, ViewChild } from '@angular/core';
import { PrivateHeaderComponent } from '../private-header/private-header.component';
import { Articles } from '../../../dto/Articles';
import { PublicService } from '../../../service/public.service';
import {ActivatedRoute, Router} from '@angular/router';
import { CurrentCard } from '../../../dto/CurrentCard';
import {PrivateService} from '../../../service/private.service';
import { AuthGuard } from '../../../guards/auth/auth.guard';



@Component({
  selector: 'app-private-articles-detail',
  templateUrl: './private-articles-detail.component.html',
  styleUrls: ['./private-articles-detail.component.css']
})
export class PrivateArticlesDetailComponent implements OnInit {

  @ViewChild(PrivateHeaderComponent, {static: true}) privateHeaderComponent: PrivateHeaderComponent;
  currentUser: any;
  currentCard: CurrentCard;
  article: Articles;
  private sub: any;
  titleError: string;
  messageError: string;

  constructor(private publicService: PublicService,
              private privateService: PrivateService,
              private authService: AuthGuard,
              private readonly router: Router,

              private route: ActivatedRoute) {

    this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));
    this.currentCard = JSON.parse(localStorage.getItem('currentCard'));
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      // tslint:disable-next-line:prefer-const
      let id = params['idName'];
      this.findArticle(id);
   }, error => {
    this.errorMessage(error);
    });
    this.privateHeaderComponent.menuActive = 'm_vida-sana';
  }

  findArticle(idName: string) {
    this.publicService.searchArticle(idName).subscribe((data: any) => {
      this.article = data;
    }, error => {
      this.errorMessage(error);
    });
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    this.sub.unsubscribe();
  }



  errorMessage(error) {
    if (error.titleError && error.messageError) {
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }

  logout() {
    this.authService.logout(false);
  }


}
