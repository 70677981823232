import { Component, OnInit, ViewChild } from '@angular/core';
import { PrivateHeaderComponent } from '../private-header/private-header.component';
import { PublicService } from '../../../service/public.service';
import { Articles } from '../../../dto/Articles';
import { CurrentCard } from '../../../dto/CurrentCard';
import {PrivateService} from '../../../service/private.service';
import {Router} from '@angular/router';
import { AuthGuard } from '../../../guards/auth/auth.guard';



@Component({
  selector: 'app-private-news',
  templateUrl: './private-news.component.html',
  styleUrls: ['./private-news.component.css']
})
export class PrivateNewsComponent implements OnInit {
  @ViewChild(PrivateHeaderComponent, {static: true}) privateHeaderComponent: PrivateHeaderComponent;
  currentUser: any;
  currentCard: CurrentCard;
  articles: Array<Articles> = [];
  localArticles: Articles[];
  year: number;
  month: number;

  titleError: string;
  messageError: string;

  constructor(private publicService: PublicService,
  private privateService: PrivateService,
              private authService: AuthGuard,
  private readonly router: Router) {
    this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));
    this.currentCard = JSON.parse(localStorage.getItem('currentCard'));
   }

  ngOnInit() {
    this.privateHeaderComponent.menuActive = 'm_noticias';
    this.searchLastNewsLetter();
  }

  searchLastNewsLetter() {
    this.publicService.searchLastNewsLetter(this.year, this.month).subscribe((data: Articles[]) => {
      this.localArticles = data;
      this.localArticles.forEach(article => {
       article.title = article.title.replace(/<h1>|<h2>|<h3>|<h4>|<\/h1>|<\/h2>|<\/h3>|<\/h4>/g, '');
       this.articles.push(article);
      });
    }, error => {
      this.errorMessage(error);
    });
  }

  errorMessage(error) {
    if (error.titleError && error.messageError) {
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }

  logout() {
    this.authService.logout(false);
  }

}
