import { Component } from '@angular/core';
import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {PrivateService} from './service/private.service';
import { environment } from 'src/environments/environment';
import {ScriptChatbotLoaderService} from './service/ScriptChatbotLoaderService';

declare function initializeMonitoring(settings: { enable: boolean; token: string; applicationId: string; service: string; env: string; }): void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  title = 'portal-junaeb';
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private privateService: PrivateService,
    private cdRef: ChangeDetectorRef,
    private scriptInjector: ScriptChatbotLoaderService) {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.activatedRoute.queryParams.subscribe(params => {
      const token = params.token;
      if (token) {
        this.privateService.destroyUserLoggedIn();
        // llamar a servicio de token awt para validar token desde url
      }
    }));

    this.startMonitoring();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {

    try {
      this.scriptInjector.loadChatbotScript();
    } catch (error) {
      console.error('Error in ScriptInjector:', error);
    }
  }

  startMonitoring(): void {
    if(environment.datadog && environment.datadog.enable){
      initializeMonitoring(environment.datadog);
    }
  }
}
