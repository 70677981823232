import { Component, OnInit } from '@angular/core';
import { CurrentCard } from '../../../dto/CurrentCard';
import {PrivateService} from '../../../service/private.service';
import {Router} from '@angular/router';
import { AuthGuard } from '../../../guards/auth/auth.guard';


@Component({
  selector: 'app-private-payment',
  templateUrl: './private-payment.component.html',
  styleUrls: ['./private-payment.component.css']
})
export class PrivatePaymentComponent implements OnInit {
  currentUser: any;
  currentCard: CurrentCard;

  constructor( private privateService: PrivateService,
               private authService: AuthGuard,
               private readonly router: Router) {
    this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));
    this.currentCard = JSON.parse(localStorage.getItem('currentCard'));
  }

  ngOnInit() {
  }


  logout() {
    this.authService.logout(false);
  }

}
