import { Component, OnInit, NgModule, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, Input } from '@angular/core';
import { FooterComponent } from '../../../shared/footer/footer.component';
import { PrivateService } from '../../../service/private.service';
import { PrivateHeaderComponent } from '../private-header/private-header.component';
import { CurrentCard } from '../../../dto/CurrentCard';
import { Banners } from '../../../dto/Banners';
import { Observable } from 'rxjs';
import {toAscii } from '../../../security/helpers/custom.validator';
import { CardHeader } from '../../../dto/CardHeader';
import { Articles } from '../../../dto/Articles';
import { PublicService } from '../../../service/public.service';
import {CommonModule} from '@angular/common';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import { AuthGuard } from '../../../guards/auth/auth.guard';

declare var startBanner: any;
declare var sliderArticulos: any;
declare var sliderNoticias: any;


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FooterComponent
  ]
})

@Component({
  selector: 'app-private-home',
  templateUrl: './private-home.component.html',
  styleUrls: ['./private-home.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivateHomeComponent implements OnInit {
  @ViewChild(PrivateHeaderComponent, {static: true}) privateHeaderComponent: PrivateHeaderComponent;
  @Input() data: Observable<any>;

  currentUser: any;
  banners: Array<Banners> = [];
  currentCard: CurrentCard;
  personIcon: string;
  cardIcon: string;
  cardCheck: string;
  articles: Array<Articles> = [];
  localArticles: Articles[];
  news: Array<Articles> = [];
  localNews: Articles[];
  year: number;
  month: number;
  error = '';
  pan = '';

  success = false;
  submitted = true;
  statusUpdate = false;

  cardHeader: CardHeader;
  cardHeaderDetail: CardHeader;
  statusCard: string;
  loadingHeader = true;
  header = false;

  titleError: string;
  messageError: string;
  visiblePagoRut: string;

  flagRequestPhysicalCard = false;
  flagIssuedPhysicalCard = false;

  activateCard: string;

  constructor(private privateService: PrivateService,
              private publicService: PublicService,
              private authService: AuthGuard,
              private cd: ChangeDetectorRef,
              private readonly router: Router) {

    this.currentUser = JSON.parse(localStorage.getItem('currentLoggedUser'));

    // tslint:disable-next-line:triple-equals
    if (this.currentUser.infoUser.paymentRutActivated == true) {
      this.visiblePagoRut = 'OK';
    }
    this.activateCard = 'Activar tarjeta';

  }

  ngOnInit() {
    // tslint:disable-next-line:no-unused-expression
    this.privateHeaderComponent.menuActive = 'm_inicio';
    this.getCurrentCardStatus();
    this.getBanner();
    this.getArticles();
    this.getNews();
    this.getFirstAccess();
    this.getBeneficiaryInfo();
  }


  getArticles() {
    this.publicService.articles(7).subscribe((data: any) => {
      this.localArticles = data;
      this.localArticles.sort(this.publicService.compareArticles);
      this.localArticles = this.localArticles.slice(0,3);
      this.localArticles.forEach(article => {
        article.title = article.title.replace('h1', 'h2');
        article.landing = article.landing + '<span class=\'plus\'>+</span>';
        this.articles.push(article);
      });
      this.cd.markForCheck();
    }, error => {
      this.errorMessage(error);
    });
  }

  getNews() {
      this.publicService.searchLastNewsLetter(this.year, this.month).subscribe((data: any) => {
        this.localNews = data;
        this.localNews.forEach(news => {
          news.landing = news.landing + '<span class=\'plus\'>+</span>';
          this.news.push(news);
        });
      }, error => {
        this.errorMessage(error);
      });

  }

  getBanner() {

    this.privateService.banner().subscribe((data: any) => {

      data.reverse().forEach(local => {
        // // tslint:disable-next-line:triple-equals
        //  if (local.title != '¿Qué información necesitas sobre tu BAES?') {
        //    local.titleBanner = local.titleBanner.replace('h1', 'h2');
        //  }

        // // tslint:disable-next-line:triple-equals
        // if (local.title == '¿Qué información necesitas sobre tu BAES?'  &&  local.confidential == true) {
        //    local.urlRedirect =  environment.base + '/sobre-la-beca-privado';
        // }
        // // tslint:disable-next-line:triple-equals
        // if (local.title == 'tu-tarjeta-baes') {
        //   local.urlRedirect =  environment.base + '/noticias-privadas';
        // }
        this.banners.push(local);
      });

      this.cd.detectChanges();
    }, error => {
      this.errorMessage(error);
    });
  }

  getFirstAccess() {
    this.privateService.checkFirstAccess().subscribe((data: any) => {
      if (data.status) {
        // tslint:disable-next-line:no-unused-expression comment-format
        //new showModalFirstAccess();
        this.cd.detectChanges();
      }
    }, error => {
      this.errorMessage(error);
    });
  }

  getBeneficiaryInfo() {
    this.flagRequestPhysicalCard = false;
    this.privateService.getBeneficiaryInfo().subscribe((data: any) => {
      if (data.response) {
        const changeStatusDate = new Date(data.data.changeStatusDate);
        const daysDiff = Math.floor((Date.now() - changeStatusDate.getTime()) / (1000 * 60 * 60 * 24));
        if (data.data.tarjetaSolicitada === 'S') {
          this.flagRequestPhysicalCard = true;
          if (daysDiff < 30) {
            this.flagIssuedPhysicalCard = true;
          }
        }
      }
    }, error => {
      this.errorMessage(error);
    });
  }

  
  onBlockButtonClick() {
    this.router.navigate(['/mi-tarjeta', 'bloquear']);
  }

  getCurrentCardStatus() {
    this.currentCard = new CurrentCard();
          this.privateService.currentCard().subscribe((data: any) => {
            this.currentCard = data;
            localStorage.setItem('currentCard', JSON.stringify(this.currentCard));
            this.getCardHeader();
            this.statusCardDisplay();
            // tslint:disable-next-line:no-unused-expression
          }, error => {
            this.errorMessage(error);
            this.loadingHeader = false;
            this.header = true;
            this.cd.detectChanges();
          });


  }

  onConfirmLockClic(result) {
    this.flagRequestPhysicalCard = result;
    this.getCurrentCardStatus();
  }

  statusCardDisplay() {
    this.pan = toAscii(this.currentCard.pan);
    const finPan = this.pan.substr(10);
    this.pan = '**********' + finPan;
    //console.log('holaaaa');
    //console.log("---->" + this.currentCard.statusCardId);
    switch (this.currentCard.statusCardId) {
      case 1: {
        this.statusCard = 'active';
        this.personIcon = 'state-person--active';
        this.cardIcon = 'state-card__icon--active';
        this.cardCheck = 'fa-check';
        break;
      }
      case 4: {
        this.statusCard = 'issued';
        this.personIcon = 'state-person--issued';
        this.cardIcon = 'state-card__icon--issued';
        this.cardCheck = 'fa-arrow-right';
        break;
      }
      default: {
        this.statusCard = 'block';
        this.personIcon = 'state-person--block';
        this.cardIcon = 'state-card__icon--block';
        this.cardCheck = 'fa-lock';
        break;
      }
    }
    this.cd.detectChanges();
  }

  getCardHeader() {
    this.cardHeader = new CardHeader();
    if (localStorage.getItem('cardHeader') != null) {
      this.cardHeader = JSON.parse(localStorage.getItem('cardHeader'));
      this.cardHeaderDetail = JSON.parse(localStorage.getItem('cardHeader'));
      this.loadingHeader = false;
      this.header = true;
      this.cd.detectChanges();
    } else {
      this.balance();
      this.balanceDetail();
    }
  }

  // tslint:disable-next-line:member-ordering
  contentPrintBanner = false;
  functionStartBannerSlider() {
    this.contentPrintBanner = true;
    // tslint:disable-next-line:no-unused-expression
    new startBanner();
    this.cd.detectChanges();
  }
  // tslint:disable-next-line:member-ordering
  contentPrintArticles = false;
  functionStartArticlesSilder() {
    this.contentPrintArticles = true;
    // tslint:disable-next-line:no-unused-expression
    new sliderArticulos();
    this.cd.detectChanges();
  }
  // tslint:disable-next-line:member-ordering
  contentPrintNews = false;
  functionStartNewsSilder() {
    this.contentPrintNews = true;
    // tslint:disable-next-line:no-unused-expression
    new sliderNoticias();
    this.cd.detectChanges();
  }

  errorMessage(error) {
    if (error.titleError && error.messageError) {
      this.success = true;
      this.error = error.messageError;
      this.statusUpdate = false;
      this.cd.detectChanges();
      this.titleError = error.titleError;
      this.messageError = error.messageError;
    }
  }

  balance() {

      this.privateService.cardHeader(
      this.currentCard.pan).subscribe((data: any) => {
      this.cardHeader = data;
      this.cd.detectChanges();
      }, error => {
            this.errorMessage(error);
      });

  }

  balanceDetail() {

      this.privateService.cardHeaderDetail().subscribe((data: any) => {
      this.cardHeaderDetail = data;
      this.cardHeaderDetail.currentAmount = this.cardHeader.currentAmount;
      localStorage.setItem('cardHeader', JSON.stringify(this.cardHeaderDetail));
      this.loadingHeader = false;
      this.header = true;
      this.cd.detectChanges();
      }, error => {
            this.errorMessage(error);
      });

  }

  logout() {
    this.authService.logout(false);
  }

  enableCard() {
    // @ts-ignore
    this.activateCard = 'Procesando...';
    this.error = '';

    // tslint:disable-next-line:triple-equals
       this.privateService.enableCard(this.currentCard.pan).subscribe((data: any) => {
         // tslint:disable-next-line:no-shadowed-variable
         this.privateService.currentCard().subscribe((data: any) => {
           this.currentCard = data;
           this.pan = toAscii(this.currentCard.pan);
           const finPan = this.pan.substr(10);
           this.pan = '**********' + finPan;
           localStorage.setItem('currentCard', JSON.stringify(this.currentCard));
           this.statusCard = 'active';
           this.personIcon = 'state-person--active';
           this.cardIcon = 'state-card__icon--active';
           this.cardCheck = 'fa-check';
//           this.seguimiento = false;
//           this.activateCard = 'Activar tarjeta';
           this.cd.detectChanges();
           this.reload();
           this.router.navigateByUrl('/mi-tarjeta');

         }, error => {
           this.errorCase(error);
         });


       }, error => {
//         this.errorCase(error);
       });
  }

  reload() {
    this.error = '';
    //this.failEmail = false;
    this.header = true;
    this.titleError = '';
    this.messageError = '';
  }

  errorCase(data) {
    if (data.titleError && data.messageError) {
      //this.failEmail = true;
      this.header = false;
      this.titleError = data.titleError;
      this.messageError = data.messageError;
    }
  }



}
