import {Component, OnInit, Injectable, ViewChild} from '@angular/core';
import {IOption} from 'ng-select';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import { PublicService } from '../../service/public.service';
import {quitarFormato, validateRut} from '../../security/helpers/rut.validator';
import { HeaderComponent } from '../../shared/footer/header/header.component';
import {Contac} from '../../models/common/Contac';



declare var formatRut: any;


@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.css']
})

@Injectable()
export class HelpCenterComponent implements OnInit {

  @ViewChild(HeaderComponent, {static: true}) headerComponent: HeaderComponent;
  contactForm: FormGroup;
  submitted = true;
  success = false;
  loading = false;
  contactReason: Array<IOption> = [];
  temp: Array<IOption> = [];
  contact: any;
  // @ts-ignore
  public contac: Contac = {};

  constructor(private formBuilder: FormBuilder,
              private publicService: PublicService) {

      this.contactForm = this.formBuilder.group({
        beneficiaryFiscalId: new FormControl('', Validators.compose([Validators.required, validateRut])),
        name: new FormControl('', Validators.compose([Validators.required])),
        ies: new FormControl('', Validators.compose([Validators.required])),
        email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
        contactReason: new FormControl('', Validators.compose([Validators.required])),
        message: new FormControl('', Validators.compose([Validators.required]))
      });

  }

  ngOnInit() {
    // tslint:disable-next-line:no-unused-expression
    new formatRut();
    this.getContactReason();
  }

  getContactReason() {
    this.temp = new Array<IOption>();
      this.publicService.contactReason().subscribe((data: any) => {
        let i = 0;
        data.forEach(d => {
          this.temp[i] = {label: d.reasonTitle, value: d.reasonTitle};
          i++;
        });
        this.contactReason = this.temp;
      }, error => {
        return error;
      });
  }



  onSubmit() {
    if (this.contactForm.invalid) {
      this.submitted = false;
      return;
    }
    this.success = true;
    this.contactMail();
  }

  contactMail() {

   this.contac.fiscalId = quitarFormato(this.contactForm.controls.beneficiaryFiscalId.value);
    this.contac.ies = this.contactForm.controls.ies.value;
    this.contac.message = this.contactForm.controls.message.value;
    this.contac.name = this.contactForm.controls.name.value;
    this.contac.subject = this.contactForm.controls.contactReason.value;
    this.contac.email = this.contactForm.controls.email.value;

    // @ts-ignore
    this.publicService.contactMailPublic(this.contac).subscribe((data: any) => {
    }, error => {
      return error;
    });
  }


}
